/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect } from "react";
// import "./styles.css";

// import { lighterPrimaryColor, darkerPrimaryColor, complementaryLighterPrimaryColor, complementaryDarkerPrimaryColor,herLightBrown,herLightRed, herLightBlue } from "../../../GlobalVariables";
import {
  useTheme,
  create,
  // ColorSet,
  ease,
  color as chartColor,
  percent as chartPercent,
  Image,
} from "@amcharts/amcharts4/core";
import { GaugeChart, ValueAxis, ClockHand } from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// link to original: https://codesandbox.io/s/staging-leaf-z6r0o?file=/src/App.js
// link to documentation: https://www.amcharts.com/docs/v4/?s=animation
// link to gauge chart documentation: https://www.amcharts.com/docs/v4/chart-types/gauge-chart/

const StrengthGauge = (props) => {
  useTheme(am4themes_animated);
  let gaugeStrength = props.score;

  //Begin creating chart. Re-renders whenever strengthGauge is updated
  useEffect(() => {
    let chart = create("chartdiv", GaugeChart);

    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.innerRadius = -25;

    let axis = chart.xAxes.push(new ValueAxis());
    axis.min = 0;
    axis.max = 200;
    axis.strictMinMax = true;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.disabled = true;

    // let colorSet = new ColorSet();

    let range0 = axis.axisRanges.create();
    range0.value = 0;
    range0.endValue = 50;
    range0.axisFill.fillOpacity = 1;
    // range0.axisFill.fill = colorSet.getIndex(0);
    // range0.axisFill.fill = chartColor(herLightBrown);
    // range0.axisFill.fill = chartColor("#b0d6d5");
    range0.axisFill.fill = chartColor("#F9F1F0");
    range0.axisFill.zIndex = -1;

    let range1 = axis.axisRanges.create();
    range1.value = 50;
    range1.endValue = 100;
    range1.axisFill.fillOpacity = 1;
    // range1.axisFill.fill = colorSet.getIndex(2);
    // range1.axisFill.fill = chartColor(lighterPrimaryColor);
    // range1.axisFill.fill = chartColor("#86BDBB");
    range1.axisFill.fill = chartColor("#FADCD9");
    range1.axisFill.zIndex = -1;

    let range2 = axis.axisRanges.create();
    range2.value = 100;
    range2.endValue = 150;
    range2.axisFill.fillOpacity = 1;
    // range2.axisFill.fill = colorSet.getIndex(4);
    // range2.axisFill.fill = chartColor(complementaryLighterPrimaryColor);
    // range2.axisFill.fill = chartColor("#6a9997");
    range2.axisFill.fill = chartColor("#F8AFA6");
    range2.axisFill.zIndex = -1;

    let range3 = axis.axisRanges.create();
    range3.value = 150;
    range3.endValue = 200;
    range3.axisFill.fillOpacity = 1;
    // range3.axisFill.fill = chartColor(complementaryDarkerPrimaryColor);
    // range3.axisFill.fill = chartColor("#3E5B5A");
    range3.axisFill.fill = chartColor("#F79489");
    range3.axisFill.zIndex = -1;

    let hand = chart.hands.push(new ClockHand());
    hand.hiddenState.properties.opacity = 0;

    hand.fill = chartColor("#000");
    hand.stroke = chartColor("#000");
    hand.endWidth = 0;
    hand.startWidth = 9;
    hand.radius = chartPercent(65);
    // hand.showValue(displayScore(gaugeStrength));
    // hand.showValue(5);
    console.log("gauge strength:", gaugeStrength);

    //Function to create new clock hand pin that will show as a question mark
    const createQuestionMarkPin = () => {
      let questionHand = chart.hands.push(new ClockHand());
      questionHand.radius = chartPercent(0);
      hand.hiddenState.properties.opacity = 0;

      questionHand.disposeChildren();

      let pin = questionHand.createChild(Image); //href for a question mark svg
      pin.href =
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KPHBhdGggZmlsbD0iIzQ0NDQ0NCIgZD0iTTkgMTFoLTNjMC0zIDEuNi00IDIuNy00LjYgMC40LTAuMiAwLjctMC40IDAuOS0wLjYgMC41LTAuNSAwLjMtMS4yIDAuMi0xLjQtMC4zLTAuNy0xLTEuNC0yLjMtMS40LTIuMSAwLTIuNSAxLjktMi41IDIuM2wtMy0wLjRjMC4yLTEuNyAxLjctNC45IDUuNS00LjkgMi4zIDAgNC4zIDEuMyA1LjEgMy4yIDAuNyAxLjcgMC40IDMuNS0wLjggNC43LTAuNSAwLjUtMS4xIDAuOC0xLjYgMS4xLTAuOSAwLjUtMS4yIDEtMS4yIDJ6Ij48L3BhdGg+CjxwYXRoIGZpbGw9IiM0NDQ0NDQiIGQ9Ik05LjUgMTRjMCAxLjEwNS0wLjg5NSAyLTIgMnMtMi0wLjg5NS0yLTJjMC0xLjEwNSAwLjg5NS0yIDItMnMyIDAuODk1IDIgMnoiPjwvcGF0aD4KPC9zdmc+Cg==";
      pin.horizontalCenter = "middle";
      pin.verticalCenter = "bottom";
      pin.rotation = 180;
      return pin;
    };

    //Animate hand to display case strength
    if (gaugeStrength === "high") {
      setInterval(function () {
        hand.showValue(180, 2000, ease.cubicOut);
      }, 1000);
      console.log("case strength high");
    } else if (gaugeStrength === "medium") {
      setInterval(function () {
        hand.showValue(135, 2000, ease.cubicOut);
      }, 1000);
      console.log("case strength medium");
    } else {
      // hand.showValue(100);
      console.log("case strength low");
      setInterval(function () {
        hand.showValue(200, 2000, ease.cubicOut);
      }, 1000);
      setInterval(function () {
        hand.showValue(10, 2000, ease.cubicOut);
      }, 2000);
      setInterval(function () {
        hand.hide(1000);
      }, 2400);
      setInterval(function () {
        createQuestionMarkPin(hand);
      }, 3500);
    }

    chart.paddingBottom = 0;
    chart.paddingTop = 0;
    chart.marginBottom = 0;

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [gaugeStrength]);

  return <div id="chartdiv" style={{ width: "100%", height: "10em" }}></div>;
};

export default StrengthGauge;
