/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Dot = (props) => {
  const dimensions = 1.25;
  return (
    <span
      data-id={props.id}
      css={css`
        align-self: flex-end;
        height: ${dimensions}em;
        width: ${dimensions}em;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 1px solid black;
        margin-bottom: 0.5em;
        bottom: 1em;
        &:hover {
          cursor: pointer;
        }
      `}
      onClick={props.onClick}
    >
      {props.checked ? (
        <span
          data-id={props.id}
          css={css`
            height: ${dimensions / 2}em;
            width: ${dimensions / 2}em;
            border-radius: 50%;
            background-color: gray;
          `}
        ></span>
      ) : null}
    </span>
  );
};

export default Dot;
