/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import {
  answerChoicePadding,
  fontFamily,
  fontSize,
  normalBorderColorForInput,
  primaryColor,
} from "../GlobalVariables";

const TextAreaInput = (props) => {
  const [inputValue, setInputValue] = useState(() => {
    const storedAnswers = window.sessionStorage.getItem("answers");
    return storedAnswers ? JSON.parse(storedAnswers)[props.questionNumber] : "";
  });

  const height = (() => {
    if (props.small) {
      return "5";
    } else if (props.medium) {
      return "10";
    } else if (props.large) {
      return "15";
    } else {
      return "3";
    }
  })();

  const changeHandler = (eventData) => {
    props.onChange(eventData);
    setInputValue(eventData.target.value);
  };

  return (
    <TextareaAutosize
      minRows={height}
      style={{ display: props.display }}
      onChange={changeHandler}
      value={inputValue}
      css={css`
        width: ${props.width};
        height: ${height};
        resize: none;
        padding: 0.5em 0.5em 0.5em 0.5em;
        font-family: ${fontFamily};
        font-size: ${fontSize};
        margin: 2em ${answerChoicePadding} 2em ${answerChoicePadding};
        min-height: 2em;
        border-radius: 4px;
        border: 2px solid ${normalBorderColorForInput};
        &:hover,
        &:focus,
        &:active {
          border-color: ${primaryColor};
          outline: none;
        }
      `}
    />
  );
};

export default TextAreaInput;
