import { Fragment } from "react";
const ChecklistSVG = (props) => {
  return (
    <Fragment>
      <svg
        id="checklist_svg_icon"
        enableBackground="new 0 0 512 512"
        height={props.dimensions}
        viewBox="0 0 512 512"
        width={props.dimensions}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fill={props.fillColor}
            d="m251.119 440.607c4.421 4.421 11.121 5.636 16.813 3.049l68.319-31.054-57.127-57.127-31.054 68.319c-2.587 5.691-1.372 12.391 3.049 16.813z"
          />
          <path
            fill={props.fillColor}
            d="m498.607 235.546-42.426-42.426c-5.858-5.858-15.355-5.858-21.213 0l-137.887 137.885 63.64 63.64 137.886-137.886c5.857-5.858 5.857-15.356 0-21.213z"
          />
          <path
            fill={props.fillColor}
            d="m124 102h160c8.284 0 15-6.716 15-15v-40c0-8.284-6.716-15-15-15h-25v-17c0-8.284-6.716-15-15-15h-80c-8.284 0-15 6.716-15 15v17h-25c-8.284 0-15 6.716-15 15v40c0 8.284 6.716 15 15 15z"
          />
          <path
            fill={props.fillColor}
            d="m358.131 435.61-77.785 35.357c-5.888 2.677-12.152 4.034-18.617 4.034-12.022 0-23.324-4.681-31.824-13.181-13.189-13.189-16.865-33.46-9.146-50.441l35.355-77.782c2.246-4.936 5.322-9.374 9.147-13.199l133.739-133.739v-104.659c0-8.284-6.716-15-15-15h-55v20c0 24.813-20.187 45-45 45h-160c-24.813 0-45-20.187-45-45v-20h-55c-8.284 0-15 6.716-15 15v415c0 8.284 6.716 15 15 15h360c8.284 0 15-6.716 15-15v-98.208l-27.672 27.672c-3.828 3.828-8.268 6.905-13.197 9.146zm-249.737-236.216c5.857-5.858 15.355-5.858 21.213 0l14.393 14.393 39.394-39.393c5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213l-50 50c-2.929 2.929-6.768 4.393-10.607 4.393s-7.678-1.464-10.606-4.394l-25-25c-5.858-5.857-5.858-15.355 0-21.212zm0 97.5c5.857-5.858 15.355-5.858 21.213 0l14.393 14.393 39.394-39.393c5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213l-50 50c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.464-10.606-4.394l-25-25c-5.859-5.858-5.859-15.356-.001-21.213zm-39.394 143.106c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm0-97.5c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm0-97.5c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm75 200c-3.839 0-7.678-1.464-10.606-4.394l-25-25c-5.858-5.858-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l14.393 14.394 39.394-39.393c5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213l-50 50c-2.929 2.929-6.768 4.393-10.607 4.393z"
          />
        </g>
      </svg>
    </Fragment>
  );
};

export default ChecklistSVG;
