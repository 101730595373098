/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const HeaderText = (props) => {
  const textAlign = (() => {
    if (props.alignment === 'left') {
      return "left";
    }
    if (props.alignment === 'right') {
      return "right";
    } else {
      return "center";
    }
  })();
  return (
    <p
      css={css`
        font-size: 2em;
        text-align: ${textAlign};
        display: ${props.display};
        margin: 1em 0 0 0;
      `}
    //   display: ${props.display};

    >
      {props.children}
    </p>
  );
};

export default HeaderText;
