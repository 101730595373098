/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import {
  answerChoicePadding,
  fontFamily,
  fontSize,
  normalBorderColorForInput,
  primaryColor,
  debug,
} from "../GlobalVariables";

const SingleLineTextInput = (props) => {
  const [inputValue, setInputValue] = useState(() => {
    const storedAnswers = window.sessionStorage.getItem("answers");
    return storedAnswers ? JSON.parse(storedAnswers)[props.questionNumber] : "";
  });
  const changeHandler = (eventData) => {
    if (debug) console.log(eventData);
    props.onChange(eventData);
    setInputValue(eventData.target.value);
  };
  return (
    <input
      value={inputValue}
      style={{ display: props.display }}
      css={css`
        width: ${props.width};
        padding: 0.1em ${answerChoicePadding} 0.1em ${answerChoicePadding};
        font-family: ${fontFamily};
        font-size: ${fontSize};
        margin: 2em 0 2em 0;
        min-height: 2em;
        border-radius: 4px;
        border: 2px solid ${normalBorderColorForInput};
        &:hover,
        &:focus,
        &:active {
          border-color: ${primaryColor};
          outline: none;
        }
      `}
      onChange={changeHandler}
    />
  );
};

export default SingleLineTextInput;
