import { Fragment } from "react";
import SASAppQuestions from "../Questions/SASAppQuestions";

const Home = () => {
  return (
    <Fragment>
      <SASAppQuestions />
    </Fragment>
  );
};

export default Home;
