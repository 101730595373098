/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Footer = (props) => {
  return (
    <div
      css={css`
        background-color: #e9d8d8;
        width: 100%;
        text-align: ${(props.center && "center") || (props.right && "right")};
        & p {
          padding: 2em;
          margin: 0;
        }
        & p:first-of-type {
          padding-top: 3em;
        }
        & p:last-of-type {
          padding-top: 0;
          padding-bottom: 3em;
        }
      `}
    >
      {props.children}
    </div>
  );
};

export default Footer;
