import { ListOfStates } from "../GlobalVariables";
import SASApp from "../SASAppMain/SASApp";

// const theButtonTitles = ["Continue", "Skip section"];

const powerImbalanceLanguage =
  "A power imbalance can make it harder to say no to unwanted sexual conduct, especially in the music industry, where professionals often know each other, or run in the same circles. Even if the encounter felt consensual at the time, a power differential can still leave people feeling traumatized.";

const SASAppQuestions = () => {
  return (
    <SASApp
      questions={[
        {
          questionNumber: 1,
          questionType: "multipleChoice",
          questionText: "Have you ever worked in the music industry?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 2,
          questionType: "multipleChoice",
          questionText:
            "Do you consider yourself a “serious fan,” (i.e. someone who follows certain bands on tour, or who is deeply embedded in a particular music scene)?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 3,
          questionType: "checkbox",
          questionText:
            "How would you best describe your work in the music industry?",
          answerChoices: [
            "Artist",
            "Worked at a record label",
            "Worked at an artist management company",
            "Worked at a promotion company",
            "Worked at some other music company",
            "Other",
          ],
        },
        {
          questionNumber: 4,
          questionType: "textArea",
          size: "medium",
          questionText: "What other roles did you have?",
        },
        {
          questionNumber: 5,
          questionType: "multipleChoice",
          questionText: "Do you work in the music industry currently?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 6,
          questionType: "multipleChoice",
          questionText:
            "Have you pursued any criminal charges or filed a formal police report?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 7,
          questionType: "none",
        },
        {
          questionNumber: 8,
          questionType: "checkbox",
          questionText:
            " Which of these options best describes the nature of your experience? (You can choose more than one option)",
          secondaryText:
            "This question helps us determine which legal protections and claims may apply to your experience.",
          answerChoices: [
            "Physical contact (any unwelcome touching of your body, such as groping)",
            "Sexual contact",
            "Verbal harassment or threats",
            "Online or written harassment",
            "Other",
          ],
        },
        {
          questionNumber: 9,
          questionType: "textArea",
          size: "medium",
          questionText:
            "If you're feeling up to it, please describe the other sexual misconduct.",
        },
        {
          questionNumber: 10,
          questionType: "multipleChoice",
          questionText:
            "Was your experience an isolated incident, or did it happen more than once?",
          answerChoices: ["Once", "More than once"],
        },
        {
          questionNumber: 11,
          questionType: "multipleChoice",
          questionText: "How long ago did the sexual misconduct begin?",
          secondaryText: "Please provide your best estimate if you are unsure.",
          answerChoices: [
            "Within the last year",
            "Between 1 and 5 years ago",
            "More than 5 years ago",
          ],
        },
        {
          questionNumber: 12,
          questionType: "dropdown",
          questionText: "In what state did the sexual misconduct happen?",
          answerChoices: ListOfStates,
        },
        {
          questionNumber: 13,
          questionType: "multipleChoice",
          questionText:
            "When did the most recent incident of sexual misconduct happen?",
          secondaryText: "Please provide your best estimate if you are unsure.",
          answerChoices: [
            "Within the last year",
            "Between 1 and 5 years ago",
            "More than 5 years ago",
          ],
        },
        {
          questionNumber: 14,
          questionType: "multipleChoice",
          questionText: "How long did the sexual misconduct last?",
          answerChoices: [
            "A week or less",
            "A month or less",
            "A few months or longer",
          ],
        },
        {
          questionNumber: 15,
          questionType: "multipleChoice",
          questionText: "Did the sexual misconduct happen in multiple states?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 16,
          questionType: "dropdown",
          questionText:
            "In what state did the sexual misconduct happen primarily?",
          answerChoices: ListOfStates,
        },
        {
          questionNumber: 17,
          questionType: "multipleChoice",
          questionText:
            "Were you under 18 at the time any of the sexual misconduct took place?",
          answerChoices: [
            "Yes",
            "No",
            "No, but I was under 18 when I first met the perpetrator(s)",
          ],
        },
        {
          questionNumber: 18,
          questionType: "none",
        },
        {
          questionNumber: 19,
          questionType: "multipleChoice",
          questionText:
            "Was the sexual misconduct carried out by one person or multiple people?",
          answerChoices: ["One person", "Multiple people"],
        },
        {
          questionNumber: 20,
          questionType: "multipleChoice",
          questionText: "Was the perpetrator someone you worked with?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 21,
          questionType: "multipleChoice",
          questionText: "Was the perpetrator your supervisor?",
          secondaryText: powerImbalanceLanguage,
          answerChoices: [
            "Yes",
            "Not exactly, but they did have the ability to harm my career or reputation",
            "No",
          ],
        },
        {
          questionNumber: 22,
          questionType: "multipleChoice",
          questionText:
            "Has the perpetrator ever worked in the music industry?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 23,
          questionType: "multipleChoice",
          questionText:
            "Has the perpetrator been a serious fan, or part of a music scene?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 24,
          questionType: "checkbox",
          questionText:
            "How would you describe the perpetrator's role in the music industry?",
          answerChoices: [
            "Artist",
            "Employed at a record label",
            "Employed at an artist management company",
            "Employed at a promotion company",
            "Worked at some other music company",
            "Other",
            "I don't know",
          ],
        },
        {
          questionNumber: 25,
          questionType: "textArea",
          size: "medium",
          questionText:
            "What other roles did the perpetrator have in the industry?",
        },
        {
          questionNumber: 26,
          questionType: "singleLineText",
          questionText:
            "If you know the name of the record label or company they worked for and feel comfortable sharing, please write the name of the label(s) or companies below.",
        },
        {
          questionNumber: 27,
          questionType: "multipleChoice",
          questionText: "Were any of the perpetrators people you worked with?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 28,
          questionType: "multipleChoice",
          questionText: "Were any of the perpetrators your supervisor?",
          secondaryText: powerImbalanceLanguage,
          answerChoices: [
            "Yes",
            "Not exactly, but they do have the ability to harm my career or reputation",
            "No",
          ],
        },
        {
          questionNumber: 29,
          questionType: "multipleChoice",
          questionText:
            "Have any of the perpetrators worked in the music industry?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 30,
          questionType: "multipleChoice",
          questionText:
            "Have any of the perpetrators been a serious fan, or part of a music scene?",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 31,
          questionType: "checkbox",
          questionText:
            "What roles did the perpetrators have in the music industry?",
          answerChoices: [
            "Artist",
            "Worked at a record label",
            "Worked at an artist management company",
            "Worked at a promotion company",
            "Worked at some other music company",
            "Other",
            "I don't know",
          ],
        },
        {
          questionNumber: 32,
          questionType: "textArea",
          size: "small",
          questionText:
            "What other roles did the perpetrator have in the industry?",
        },
        {
          questionNumber: 33,
          questionType: "textArea",
          size: "medium",
          questionText:
            "If you know the name of the record label or company they worked for and feel comfortable sharing, please check the name of the label(s) or companies below.",
        },
        {
          questionNumber: 34,
          questionType: "none",
        },
        {
          questionNumber: 35,
          questionType: "checkbox",
          questionText:
            "Did you experience any of the following professional consequences after the sexual misconduct occurred?",
          answerChoices: [
            "Forced to sign an NDA (non-disclosure agreement)",
            "Reprimanded at my job",
            "Fired or forced to quit my job",
            "Lost out on work opportunities",
            "Blacklisted from the industry",
            "Experienced damage to my reputation",
            "Other", 
            "I don't know",
          ],
        },
        {
          questionNumber: 36,
          questionType: "textArea",
          size: "medium",
          questionText: "What other retaliation or consequences did you face?",
        },
        {
          questionNumber: 37,
          questionType: "multipleChoice",
          questionText:
            "Did you tell anyone what happened? (This includes casual reporting to a friend, family member, colleague, or HR.)",
          secondaryText:
            "Whether you chose to tell someone is always up to you, and there's never one “normal” or “right” way to respond. We ask this question because it can be helpful from a legal perspective to know who might have known about the misconduct.",
          answerChoices: ["Yes", "No"],
        },
        {
          questionNumber: 38,
          questionType: "checkbox",
          questionText: "Who did you tell?",
          answerChoices: [
            "HR or supervisor",
            "Police",
            "Family member or friend",
            "Other",
          ],
        },
        {
          questionNumber: 39,
          questionType: "textArea",
          size: "medium",
          questionText: "Please describe who you told.",
        },
        {
          questionNumber: 40,
          questionType: "checkbox",
          questionText:
            "Where does/do the perpetrator(s) fall on the company hierarchy?",
          answerChoices: [
            "Perpetrator(s) is/are a high-level employee",
            "Perpetrator(s) is/are a mid-level employee",
            "Perpetrator(s) is/are a regular employee",
          ],
        },
        {
          questionNumber: 41,
          questionType: "checkbox",
          questionText:
            "How well known is/are the perpetrator(s) in the music industry?",
          answerChoices: [
            "Perpetrator(s) is/are famous",
            "Perpetrator(s) is/are somewhat famous",
            "Perpetrator(s) is/are relatively niche",
            "Perpetrator(s) is/are very niche",
          ],
        },
        {
          questionNumber: 42,
          questionType: "none",
        },
        {
          questionNumber: 43,
          questionType: "none",
          // questionText:
          //   "Based on the information you provided...[first 1-2 sentences of customized report.] Continue reading your report below:",
          // answerChoices: [
          //   "Download report and get free consultation on it",
          //   "Download report only",
          //   "Get free consultation only",
          // ],
        },
      ]}
    />
  );
};

export default SASAppQuestions;
