import { useLocation } from "react-router-dom";
import { home, about } from "../GlobalVariables";

const useCurrentPage = () => {
  const currentPath = useLocation();
  if (currentPath.pathname === "/") return home;
  if (currentPath.pathname === "/about/") return about;
};

export { useCurrentPage };
