/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { fontSize } from "../GlobalVariables";

const ParagraphText = (props) => {
  const textAlign = (() => {
    if (props.alignment === 'center') {
      return "center";
    }
    if (props.alignment === 'right') {
      return "right";
    } 
    if (props.alignment === 'justify') {
      return "justify";
    }
    else {
      return "left";
    }
  })();
  const lineSpacing = props.lineHeight
  return (
    <p
      css={css`
        font-size: ${fontSize};
        display: ${props.display};
        text-align: ${textAlign};
        margin: 1em 0 0 0;
        line-height: ${lineSpacing}
      `}
    >
      {props.children}
    </p>
  );
};

export default ParagraphText;
