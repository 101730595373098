/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
  darkTealGradient,
  debug,
  lighterTealGradient,
} from "../GlobalVariables";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( WHAT IS THE APPBUTTON COMPONENT )))
// This component is the standard button for use in the survivor app.
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( HOW TO USE THIS COMPONENT )))
// There are a number of color choices to use for the button. They are all class names that contain the word "gradient". You can Ctrl + F for "gradient" to find them.
// The text that you want to appear inside the button should go between the AppButton tags: <AppButton>YourText</AppButton>.
// You MUST pass a buttonId into the button as props. You MUST also pass function call or reference into the onClick prop to tell the button what to do when it is clicked.
// Optionally, you may pass a buttonColor (equal to the gradient you chose) as props. You can also pass a buttonMargin, which determines how much space should surround the button.
// === Required Props ===
// 1. buttonId
// 2. onClick
// === Optional Props ===
// 1. buttonColor
// 2. buttonMargin
// === Color Choices ===
// 1.
// === Example Usage ===
//<AppButton
//  buttonId="back"
//  buttonColor="complementary-color-gradient1"
//  buttonMargin="5em"
//  onClick={(event) => onClick(event)}
//  theCSS={`
//            grid-area: buttonBack;
//            visibility: ${
//              appState.currentQuestion <= startingSlide + 1 ||
//              appState.currentQuestion > lastQuestion + 2
//                ? "hidden"
//                : "visible"
//            };
//          `}
//>
//  Back
//</AppButton>;
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

const AppButton = (props) => {
  //   const targetURL = props.targetURL ? props.targetURL : "";
  const locationOnPage = props.locationOnPage || "left";
  const displayType = props.display || "block";
  const buttonDisplayType = props.buttonDisplayType || "inline-block";
  const borderRadius = props.borderRadius ? props.borderRadius : "3px";
  const buttonId = props.buttonId ? props.buttonId : "standard";
  const buttonHeight = props.buttonHeight ? props.buttonHeight : "";
  const buttonWidth = props.buttonWidth
    ? props.buttonWidth.toString() + "px"
    : "initial";
  const buttonPadding = props.buttonPadding
    ? props.buttonPadding
    : ".84em 2.14em";
  const buttonMargin = props.buttonMargin ? props.buttonMargin : ".375em";
  const fontSize = props.fontSize ? props.fontSize : ".81em";
  const buttonTransform = props.buttonTransform ? props.buttonTransform : "";
  const ifFlex = () => {
    if (buttonDisplayType === "flex") {
      return "align-items: center; justify-content: center;";
    } else {
      return "";
    }
  };

  const rippleEffect = (e) => {
    const theButton = e.target;
    const circle = document.createElement("span");
    const diameter = Math.max(theButton.clientWidth, theButton.clientHeight);
    const radius = diameter / 2;
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${
      e.clientX - theButton.getBoundingClientRect().left - radius
    }px`;
    circle.style.top = `${
      e.clientY - theButton.getBoundingClientRect().top - radius
    }px`;

    circle.classList.add("ripple");
    const ripple = theButton.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }
    theButton.appendChild(circle);
  };

  const handleClick = (e) => {
    if (!props.disabled) {
      rippleEffect(e);
    }
    // setTimeout(() => {
    // window.location.href = targetURL;
    // }, 500);

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // ((( ONCLICK PROP = USED FOR GETTING THE INNER HTML OF THE BUTTON )))
    // The parent component then checks the inner HTML to see if the user clicked the "Back" or "Next" button.
    // flag for AB: added back props.onClick
    if (props.onClick) {
      props.onClick(e.target.innerHTML);
    }
    if (props.clickedNext) {
      props.clickedNext("next");
    }
    if (props.onStartClicked) {
      props.onStartClicked();
    }
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    if (props.skipToQuestion) {
      if (props.skipToQuestionNumber) {
        props.skipToQuestion(+props.skipToQuestionNumber);
      } else {
        props.skipToQuestion();
      }
    }
    if (props.onDownloadClick) {
      props.onDownloadClick(); //pull in answers array
    }
    if (props.calculate) {
      if (debug) console.log("the button was clicked");
      const theTotalPoints = props.calculate();
      if (debug) console.log("total points:", theTotalPoints);
      return theTotalPoints;
    }
  };

  return (
    <div
      className={`displayType ${buttonId} site-button__` + locationOnPage}
      css={css`
        ${props.theCSS}
      `}
    >
      <style>{`
              .site-button:not(:disabled):not(.disabled) {
                ${props.disabled ? "cursor: not-allowed" : "cursor:pointer"};
              }
              .${buttonId} .site-button{
                height: ${buttonHeight};
                margin: ${buttonMargin};
                padding: ${buttonPadding};
                border-radius: ${borderRadius};
                transform: ${buttonTransform};
              }
              .${buttonId} .site-button {
                  color: ${props.buttonTextColor || "#fff"};
                  transition: .5s ease;
                  text-transform: uppercase;
                  word-wrap: break-word;
                  white-space: normal;
                  border: 0;
                  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
                  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                  font-size: ${fontSize};
                  font-weight: 400;
                  text-align: center;
                  vertical-align: middle;
                  line-height: 1.5;
                  font-family: inherit;
              }
              ${
                props.disabled
                  ? ""
                  : `.site-button:hover {
                outline: 0;
                box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%), inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
              }`
              }
              .waves-effect {
                  position: relative;
                  overflow: hidden;
                  user-select: none;
              }
              .purple-gradient {
                  background: linear-gradient(40deg,#ff6ec4,#7873f5);
              }
              .young-passion-gradient {
                background: linear-gradient(120deg,#ff8177 0,#ff867a 0,#ff8c7f 21%,#f99185 52%,#cf556c 78%,#b12a5b 100%);
              }
              .warm-flame-gradient {
                background: linear-gradient(45deg,#ff9a9e 0,#fad0c4 99%,#fad0c4 100%); 
              }
              .ripe-malinka-gradient {
                background: linear-gradient(120deg,#f093fb 0,#f5576c 100%);
              }
              .site-red-gradient {
                background: linear-gradient(145deg, #DC143C 0%, #B22222 100%)
              }
              .aqua-gradient {
                background: linear-gradient(40deg,#2096ff,#05ffa3) !important;
              }
              .morpheus-den-gradient {
                background-image: linear-gradient(to top, rgb(38, 165, 166) 0px, rgb(41, 6, 82) 100%);
              }
              .near-moon-gradient {
                background-image: linear-gradient(to top, rgb(21, 141, 136) 0px, rgb(81, 48, 101) 100%);
              }
              .rare-wind-gradient {
                background-image: linear-gradient(to top, rgb(20, 94, 92) 0px, rgb(74, 2, 25) 100%);
              }
              .rich-blue-gradient {
                background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
              }
              .pink-gradient {
                background: linear-gradient(50deg, #f06b93, #f2224c, #b33b59);
              }
              .blue-gradient {
                background: linear-gradient(40deg,#45cafc,#303f9f) !important;
              }
              .peach-gradient {
               background: linear-gradient(40deg,#ffd86f,#fc6262) !important;
              }
              .night-fade-gradient {
                background-image: linear-gradient(to top,#a18cd1 0,#fbc2eb 100%);
              }
              .spring-warmth-gradient {
               background-image: linear-gradient(to top,#fad0c4 0,#ffd1ff 100%);
             }
             .juicy-peach-gradient {
              background-image: linear-gradient(to right,#ffecd2 0,#fcb69f 100%);
            }
            .rainy-ashville-gradient {
              background-image: linear-gradient(to top,#fbc2eb 0,#a6c1ee 100%);
            }
            .sunny-morning-gradient {
              background-image: linear-gradient(120deg,#f6d365 0,#fda085 100%);
            }
            .lady-lips-gradient {
              background-image: linear-gradient(to top,#ff9a9e 0,#fecfef 99%,#fecfef 100%);
            }
            .winter-neva-gradient {
              background-image: linear-gradient(120deg,#a1c4fd 0,#c2e9fb 100%);
            }
            .frozen-dreams-gradient {
              background-image: linear-gradient(to top,#fdcbf1 0,#fdcbf1 1%,#e6dee9 100%);
            }
            .dusty-grass-gradient {
              background-image: linear-gradient(120deg,#d4fc79 0,#96e6a1 100%);
            }
            .tempting-azure-gradient {
              background-image: linear-gradient(120deg,#84fab0 0,#8fd3f4 100%);
            }
            .heavy-rain-gradient {
              background-image: linear-gradient(to top,#cfd9df 0,#e2ebf0 100%);
            }
            .amy-crisp-gradient {
              background-image: linear-gradient(120deg,#a6c0fe 0,#f68084 100%);
            }
            .mean-fruit-gradient {
              background-image: linear-gradient(120deg,#fccb90 0,#d57eeb 100%);
            }
            .deep-blue-gradient {
              background-image: linear-gradient(120deg,#e0c3fc 0,#8ec5fc 100%);
            }
            .cloudy-knoxville-gradient {
              background-image: linear-gradient(120deg,#fdfbfb 0,#ebedee 100%);
            }
            .rare-wind-gradient {
              background-image: linear-gradient(to top,#a8edea 0,#fed6e3 100%);
            }
            .complementary-color-gradient1 {
              background-image: linear-gradient(to right, #ed8045 0, #f2a47a 100%);
            }
            .complementary-color-gradient2 {
              background-image: linear-gradient(to right, #f2a47a 0, #ed8045 100%);
            }
            .complementary-color-gradient3 {
              background-image: linear-gradient(to right, #ffc985fc 0, #ed8045 100%);
            }
            .complementary-color-gradient4 {
              background-image: linear-gradient(to left, #ffc985fc 0, #ed8045 100%);
            }
            .darker-teal-gradient {
              background-image: ${darkTealGradient};
            };
            .lighter-teal-gradient {
              background-image: ${lighterTealGradient};
            }
            .grayed-out {
              background: #BCC2C2;
            }
              span.ripple {
                  position: absolute;
                  border-radius: 50%;
                  transform: scale(0);
                  animation: ripple 600ms linear;
                  background-color: rgba(255, 255, 255, 0.7);
                }
                
                @keyframes ripple {
                  to {
                    transform: scale(4);
                    opacity: 0;
                  }
                }
                .site-button__left {
                  text-align: left;
                }
                .site-button__center {
                  text-align: center;
                }
                .site-button__right {
                  text-align: right;
                }
                .displayType.${buttonId} {
                  display: ${displayType};
                }
                .displayType.${buttonId} .site-button {
                  display: ${buttonDisplayType};
                  ${ifFlex()}
                }
          `}</style>
      <button
        id={props.theButtonId ? props.theButtonId : buttonId}
        css={css`
          width: ${buttonWidth};
          background-image: ${lighterTealGradient};
        `}
        className={
          "site-button waves-effect " +
          (props.buttonColor || "complementary-color-gradient3") +
          " " +
          props.className
        }
        onClick={handleClick}
        type="button"
        style={{ display: props.display }}
      >
        {props.children}
      </button>
    </div>
  );
};

export default AppButton;
