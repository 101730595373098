/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import QuestionText from "../../Text/QuestionText";
import { transitionTextSize } from "../../GlobalVariables";

const AbuseTransitionSlide = ({
  header,
  transitionText,
  transitionTextItalics,
  children,
}) => {
  return (
    <div
      css={css`
        display: block;
        text-align: center;
      `}
    >
      <QuestionText center>{header}</QuestionText>
      <br />
      {children}
      <p
        css={css`
          font-size: ${transitionTextSize};
        `}
      >
        {transitionText}
      </p>
      <p
        css={css`
          font-style: italic;
          color: gray;
        `}
      >
        {transitionTextItalics}
      </p>
    </div>
  );
};

export default AbuseTransitionSlide;
