import QuestionText from "../../../Text/QuestionText";
import { Fragment } from "react";
import FinalScoreGraphic from "./FinalScoreGraphic";
// import Circle from "../../../Buttons/Circle";
import DownloadResults from "../../../Buttons/DownloadResults";
import AppButton from "../../../Buttons/AppButton";
import ParagraphText from "../../../Text/ParagraphText";

import { buttonColor } from "../../../GlobalVariables";
import { fontSize } from "../../../GlobalVariables";
import { transitionTextSize } from "../../../GlobalVariables";
import StrengthGauge from "./StrengthGauge";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//Function to collect and format Q/A data for Downloadable CSV creation

const compileCSV = (props) => {
  // let answerUrl =
  //   "https://surveys.classlawgroup.com/sexual-assault-intake-form/?survey=";
  let downloadData = [];
  let questionTextArray = [];
  let answerKeys = Object.keys(props.answers); // use the keys to get question text
  let allAnswers = Object.values(props.answers); // set aside all survey answers

  //use keys from props.answers to find the right question text for each answer
  answerKeys.forEach((eachKey) => {
    const questionIndex = props.questions.findIndex(
      (eachQuestion) =>
        eachQuestion.questionNumber.toString() === eachKey.toString()
    );
    questionTextArray.push(props.questions[questionIndex].questionText);
  });

  // assemble CSV file question and answer data for download file
  allAnswers.forEach((eachAnswer, i) => {
    downloadData.push({
      questionText: questionTextArray[i],
      questionAnswer: eachAnswer,
    });
  });
  // console.log("my download data nerd: ", downloadData);
  // console.log("props questions: ", props.questions);
  // console.log("all answers: ", props.answers);

  return downloadData; //downloadData format = [{a: b, c: d}, {a: e, c: f}]
};

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// headers needed for CSV download file
let headers = [
  { label: "Question", key: "questionText" },
  { label: "My Response", key: "questionAnswer" },
];

// const dimensions = "10em";

const EndBuckets = (props) => {
  const strength = props.score;
  const theUrl = props.generatedUrl;

  function StrongCaseTitle(props) {
    return (
      <QuestionText center>
        Based on the information you provided, here is your personalized report
        analysis:
        <br />
        {/* <br /> */}
        <p style={{ textAlign: "" }}>EXTREMELY STRONG CASE</p>
      </QuestionText>
    );
  }
  function MediumCaseTitle(props) {
    return (
      <QuestionText center>
        Based on the information you provided, here is your personalized report
        analysis:
        <br />
        {/* <br /> */}
        <p style={{ textAlign: "" }}>STRONG CASE</p>
      </QuestionText>
    );
  }

  function WeakCaseTitle(props) {
    return (
      <QuestionText center>
        Based on the information you provided, it is unclear whether you have a
        strong legal claim.
        <br />
        <br />
      </QuestionText>
    );
  }

  function StrongCaseDescription(props) {
    return (
      <div
        style={{
          paddingTop: "0em",
          textAlign: "justify",
        }}
      >
        <ParagraphText alignment="justify">
          You have an extremely strong claim, which means that certain facts you
          provided might make it easier to prove a sexual assault civil lawsuit.
          Civil sexual abuse lawsuits can help you move forward, regain control,
          and protect others from facing the same abuse in the future. Through
          civil lawsuits, you may also be able to achieve a sense of justice,
          and in some cases, obtain compensation for what happened to you.
          <br />
          <br />
          Typically, the law gives survivors of sexual abuse a set period of
          time to file a lawsuit after the abuse occurred. This can be
          challenging for survivors, because for many, it can take years to
          process and unpack what happened, and to feel comfortable talking
          about the abuse, or naming it as such. But as more survivors stand up,
          speak out, and raise awareness about their experiences, some states
          have passed laws giving victims of sexual abuse additional time to
          come forward and seek justice.
          <br />
          <br />
          We want you to know that we believe you, and we are sincerely sorry
          that you experienced this harm. While we know that a lawsuit cannot
          change your experience, other survivors have reported that taking this
          step has helped them to move forward and heal. As more people come
          forward and take control, we hope to chip away at wrongdoing abuser by
          abuser, industry by industry, and ultimately bring about larger
          societal changes.
        </ParagraphText>
      </div>
    );
  }

  function MediumCaseDescription(props) {
    return (
      <div
        style={{
          // fontSize: "1.5em",
          paddingTop: "0em",
          textAlign: "justify",
        }}
      >
        <ParagraphText alignment="justify">
          You have a strong claim, which means that some of the facts you
          provided might make it easier to prove and win a sexual assault civil
          lawsuit that can help you protect yourself and others, achieve a sense
          of justice, and obtain compensation. We think it would be helpful for
          you to speak with an attorney to get a more detailed case evaluation.
          <br />
          <br />
          Typically, the law gives survivors of sexual abuse a set period of
          time to file a lawsuit after the abuse occurred. This can be
          challenging for survivors, because for many, it can take years to
          process and unpack what happened, and to feel comfortable talking
          about the abuse, or naming it as such. But as more survivors stand up,
          speak out, and raise awareness about their experiences, some states
          have passed laws giving victims of sexual abuse additional time to
          come forward and seek justice.
          <br />
          <br />
          Whether or not you decide to speak with an attorney for a more
          detailed analysis, we want you to know that we believe you, and we are
          sincerely sorry that you experienced this harm.
        </ParagraphText>
      </div>
    );
  }

  function WeakCaseDescription(props) {
    return (
      <div
        style={{
          fontSize: { transitionTextSize },
          paddingTop: "0em",
          textAlign: "justify",
        }}
      >
        <ParagraphText alignment="justify">
          We think it could be worthwhile for you to speak with an attorney to
          get a more detailed case evaluation. While the legal system can be
          challenging to navigate at times, a consultation with an experienced
          attorney may provide more clarity about potential legal claims.
          <br />
          <br />
          Typically, the law gives survivors of sexual abuse only a short window
          of time to file a lawsuit after the abuse occurred. This can be
          challenging for survivors, because for many, it can take years to feel
          comfortable talking about the abuse, or naming it as such. But as more
          survivors speak out and raise awareness about their experiences, more
          states have passed laws giving victims of sexual abuse additional time
          to come forward and seek justice.
        </ParagraphText>
      </div>
    );
  }

  const caseStrength = (() => {
    if (strength > 0 && strength < 150) {
      return "low";
    }
    if (strength > 149 && strength < 200) {
      return "medium";
    }
    if (strength > 199) {
      return "high";
    }
    return "none";
  })();

  const TitleText = () => {
    if (caseStrength === "high") {
      return <StrongCaseTitle />;
    }
    if (caseStrength === "medium") {
      return <MediumCaseTitle />;
    } else {
      return <WeakCaseTitle />;
    }
  };

  const EndText = () => {
    if (caseStrength === "high") {
      return <StrongCaseDescription />;
    }
    if (caseStrength === "medium") {
      return <MediumCaseDescription />;
    } else {
      return <WeakCaseDescription />;
    }
  };

  //HERE is what is actually rendered
  return (
    <Fragment>
      <TitleText />

      <div
        style={{
          paddingTop: "1em",
          paddingBottom: "1em",
        }}
      >
        <StrengthGauge score={caseStrength}></StrengthGauge>
      </div>
      {/* Following section is OLD GAUGE. Replaced by Strength Gauge above
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div
          style={{
            //   flexGrow: 1,
            //   padding: "5em",
            transform: `rotate(90deg) scaleX(-1)`,
            justifyContent: "center",
          }}
        >
          <FinalScoreGraphic endBucket={caseStrength} />
        </div>
      </div> */}

      <EndText />
      {/* <br /> */}
      <p
        style={{
          textAlign: "center",
          fontSize: "1.2em",
          paddingTop: "1.5em",
          lineHeight: "100%",
        }}
      >
        <strong>If you feel ready to discuss your legal claim:</strong>
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "0.3em",
          fontSize: "1.2em",
        }}
      >
        <AppButton
          buttonId="sendSurvey"
          buttonColor={buttonColor}
          // surveyUrl={answerUrl}
          onClick={() => {
            window.open(theUrl);
            //   // (window.location.href = { createHref })
          }}
        >
          share results with legal team
        </AppButton>
      </div>

      <p
        style={{
          textAlign: "center",
          fontSize: "1.2em",
          color: "#999999",
          paddingBottom: "0px",
          paddingTop: "1.5em",
          lineHeight: "100%",
        }}
      >
        <strong>If you would like to keep a copy of your responses:</strong>
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0",
          paddingTop: "0px",
          margin: "0",
          lineHeight: "80%",
          paddingBottom: "50px",
        }}
      >
        <DownloadResults
          // theButton={
          //   <AppButton buttonId="download">Download my results</AppButton>
          // }
          theData={compileCSV(props)}
          theHeaders={headers}
        />
      </div>

      <p
        style={{
          fontSize: "0.9em",
          color: "grey",
          padding: "0px 0em 20px 0em",
          textAlign: "justify",
        }}
      >
        <strong>How did we calculate the strength of your claim?</strong> We use
        a variety of factors to customize your personal analysis. Some of the
        main areas of focus to determine the potential strength of your claim is
        the state you live, the time period the abuse occurred, the relationship
        you had to your abuser, and the harm that occurred.
      </p>

      {/* <p style={{ fontSize: "1.25em", textAlign: "justify" }}> */}
      <ParagraphText alignment="justify">
        We hope these survey results will make it easier for you to decide if
        pursuing a sexual assault civil lawsuit is the right choice for you.
        Please note that these survey results do not constitute legal advice,
        and it’s a good idea to still speak with an experienced attorney to go
        over your results, if and when you feel ready to do that.
      </ParagraphText>
    </Fragment>
  );
};

export default EndBuckets;
