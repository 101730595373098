/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import Select from "react-select";
import {
  primaryColor,
  lighterPrimaryColor,
  darkerPrimaryColor,
  normalBorderColorForInput,
  lighterTextColor,
  answerChoicePadding,
} from "../GlobalVariables";

const SingleSelectDropdown = (props) => {
  const [inputValue, setInputValue] = useState(() => {
    const storedAnswers = window.sessionStorage.getItem("answers");
    return storedAnswers ? JSON.parse(storedAnswers)[props.questionNumber] : "";
  });
  const options = props.answerChoices;
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //   color: state.isSelected ? "red" : "black",
      //   borderColor: "red",
    }),
    control: (base, state) => ({
      ...base,
      //   border: 0,
      // This line disable the blue border
      boxShadow: "none",
      border: state.isFocused
        ? "2px solid " + primaryColor
        : "2px solid " + normalBorderColorForInput,
      "&:hover": {
        border: "2px solid " + primaryColor,
        width: props.width,
      },
      //   "&:active": {
      //     backgroundColor: "red",
      //   },
      //   "&:target": {
      //     border: "1px solid orange",
      //   },
    }),
    singleValue: (provided, state) => {
      //   const opacity = state.isDisabled ? 0.5 : 1;
      //   const transition = "opacity 300ms";

      return {
        ...provided,
        // opacity,
        // transition
      };
    },
  };

  const changeHandler = (eventData) => {
    props.onChange(eventData);
    setInputValue(eventData.value);
  };

  return (
    <div
      style={{
        display: props.display,
        textAlign: "center",
        margin: `0 ${answerChoicePadding} 0 ${answerChoicePadding}`,
      }}
    >
      {props.secondaryText ? (
        <p
          css={css`
            color: ${lighterTextColor};
            font-size: 1.25em;
            padding: 0.5em;
          `}
        >
          {props.secondaryText}
        </p>
      ) : null}
      <br />
      <div
        style={{ width: `${props.width}`, textAlign: "left", margin: "auto" }}
      >
        <Select
          onChange={changeHandler}
          value={{ value: inputValue, label: inputValue }}
          options={options}
          styles={customStyles}
          isClearable="true"
          isSearchable="true"
          escapeClearsValue="true"
          blurInputOnSelect="true"
          tabSelectsValue="true"
          theme={(theme) => ({
            ...theme,
            // borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: lighterPrimaryColor,
              primary: darkerPrimaryColor,
            },
          })}
        />
      </div>
    </div>
  );
};

export default SingleSelectDropdown;
