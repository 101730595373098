import QuestionText from "../../../Text/QuestionText";
import { Fragment, useEffect } from "react";
import AppButton from "../../../Buttons/AppButton";
import { buttonColor } from "../../../GlobalVariables";
import ParagraphText from "../../../Text/ParagraphText";

const SubmitSurvey = (props) => {
  useEffect(() => {});
  return (
    <Fragment>
      <QuestionText>
        Thank you for answering these questions. We will now analyze your answers and provide a personalized case assessment.
      </QuestionText>
      <ParagraphText alignment="justify">Please know that the purpose of this report is to provide you with an understanding of your case strength and potential legal claims based on some key information. But we know that every situation is unique, and it can be challenging to capture all the nuances. Regardless of the report outcome, we believe you, and we are here to help.
      <br/><br/>
      </ParagraphText>
      <ParagraphText alignment="center">
        Please click below to proceed to your case assessment.
      <br/><br/>
      </ParagraphText>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AppButton
          onClick={props.onClickSubmitAndContinue}
          calculate={props.calculatePoints}
          buttonColor={buttonColor}
        >
          View My Report
        </AppButton>
      </div>
    </Fragment>
  );
};

export default SubmitSurvey;
