import { CSVLink } from "react-csv";

// This component holds the functionality to download a CSV

const DownloadResults = (props) => {
  const headers = props.theHeaders;

  const data = props.theData;

  return (
    <CSVLink
      style={{ fontSize: "1.2em", color: "#626588", paddingTop: "0px" }}
      data={data}
      headers={headers}
      filename={"Anonymous_Case_Assessment_Answers.csv"} // need a sensitive file name
    >
      <strong>Download your results</strong>
    </CSVLink>
  );
};

export default DownloadResults;
