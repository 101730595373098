/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const BoxTitle = (props) => {
  return (
    <p
      css={css`
        color: ${props.color};
        font-weight: bold;
        font-size: 1em;
        letter-spacing: 0.05em;
        text-align: center;
        line-height: 1.25;
        margin-left: 0.25em;
        margin-right: 0.25em;
      `}
    >
      {props.children}
    </p>
  );
};

export default BoxTitle;
