/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  primaryColor,
  textColor,
  lighterTextColor,
  answerChoicePadding,
  slideTransitionKeyframe,
  animationCSS,
} from "../GlobalVariables";
import { useState } from "react";

// This component is based in part on the code for "Wobble", found here: https://codepen.io/Zaku/pen/KRrbvN But keep in mind that the code has been heavily refactored.

// TODO -> eliminate <input>

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( CSS for the Wobble Effect )))
//
const wobbleKeyframe = `@keyframes wobble {
  0% {
    transform: scale(1) rotate(0deg)
  }
  40% {
    transform: scale(1.1, .9) rotate(5deg)
  }
  70% {
    transform: scale(.9, 1.1) rotate(-3deg)
  }
  80% {
    transform: scale(1.05, .95) rotate(1deg)
  }
  to {
    transform: scale(1) rotate(0deg)
  }
}`;
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

const RadioButton = (props) => {
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( Component Variables )))
  //
  const radioLineColor = "white";
  const inputSize = 24;
  const halfInputSize = inputSize / 2;
  const offsetToCenterText = inputSize / 5;
  const borderRadius = inputSize / 15;
  const borderColor = "rgba(0, 0, 0, .1)";
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( State to Hold Current Answer )))
  //
  const [currentAnswer, setCurrentAnswer] = useState(() => {
    const storedAnswers = window.sessionStorage.getItem("answers");
    return storedAnswers ? JSON.parse(storedAnswers)[props.questionNumber] : "";
  });
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( Handler for When an Answer is Selected )))
  //
  const handleSelection = (event) => {
    setCurrentAnswer(event.target.value);
  };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( JSX for this component )))
  //
  return (
    <div
      onChange={(event) => props.onChange(event.target.value)}
      css={css`
        display: ${props.display};
        animation: ${props.display ? animationCSS : ""};
        ${props.display ? slideTransitionKeyframe : ""};
      `}
    >
      <div style={{ display: "block" }}>
        {props.secondaryText ? (
          <p
            css={css`
              color: ${lighterTextColor};
              font-size: 1.25em;
              padding: 0.5em;
            `}
          >
            {props.secondaryText}
          </p>
        ) : null}
        {props.answerChoices.map((value, index) => {
          return (
            <div
              key={`radioOuterDiv-question${props.QuestionNumber}-answer${index}`}
              className="answer-choice__wrapper"
              css={css`
                margin-top: 1em;
                margin-bottom: 1em;
              `}
            >
              <div
                key={`radioInnerDiv-question${props.QuestionNumber}-answer${index}`}
                css={css`
                  // This div's CSS
                  user-select: none;
                  display: inline;
                  font-size: ${inputSize}px;
                  text-rendering: optimizeLegibility;
                  color: #42494f;
                  // CSS applicable to children
                  & input {
                    display: none;
                  }
                  & label {
                    cursor: pointer;
                    text-align: left;
                  }
                  & .input__box {
                    border-radius: ${inputSize}px;
                    opacity: 0.9;
                    position: relative;
                    overflow: hidden;
                  }
                  & .input__box:after,
                  & .input__box:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    box-sizing: border-box;
                    transform-origin: ${halfInputSize}px ${halfInputSize}px;
                    transform: scale(0);
                    width: ${inputSize}px;
                    height: ${inputSize}px;
                    border: ${borderRadius}px solid transparent;
                    border-radius: ${inputSize}px;
                    transition: transform 0.2s ease;
                  }

                  & .input__box:before {
                    transition-delay: 0.2s;
                    background-color: #fff;
                    background-color: ${primaryColor};
                  }

                  & .input__box:after {
                    transition-delay: 0s;
                    background-color: ${radioLineColor};
                  }
                  & label:hover .input__box {
                    opacity: 1;
                  }
                  & input.checked + .input__box {
                    transition-delay: 0s;
                    animation-name: wobble;
                    animation-duration: 0.3s;
                    animation-timing-function: ease;
                  }
                  & input.checked + .input__box:before {
                    transition-delay: 0s;
                    transform: scale(1.1);
                  }
                  & input.checked + .input__box:after {
                    transition-delay: 0.2s;
                    transform: scale(0.33);
                  }
                  & input.checked ~ .input__box--text {
                    color: ${textColor};
                  }
                  ${wobbleKeyframe}
                `}
                className={`question${props.questionNumber} input--primary`}
                style={{ padding: answerChoicePadding }}
              >
                <label
                  key={`radioLabel-question${props.QuestionNumber}-answer${index}`}
                >
                  <input
                    key={`radioInput-question${props.QuestionNumber}-answer${index}`}
                    type="radio"
                    name="radio--light-primary"
                    id={`question${props.QuestionNumber}-answer${index}`}
                    value={value}
                    onClick={(event) => handleSelection(event)}
                    className={
                      currentAnswer === value ? "checked" : "unchecked"
                    }
                  />
                  <span
                    key={`radioSpan-question${props.QuestionNumber}-answer${index}`}
                    className="input__box"
                    css={css`
                      display: inline-block;
                      width: ${inputSize}px;
                      height: ${inputSize}px;
                      margin-right: calc(${inputSize}px / 2);
                      background-color: ${primaryColor};
                      border: ${borderRadius}px solid ${borderColor};
                      vertical-align: calc(
                        (11px - ${inputSize}px) / 2 - 11px / 4
                      );
                      transition: background-color 0.1s ease;
                      transition-delay: 0.05s;
                    `}
                  ></span>
                  <div
                    key={`radioLastDiv-question${props.QuestionNumber}-answer${index}`}
                    className="input__box--text"
                    css={css`
                      display: inline-flex;
                      transform: translateY(${offsetToCenterText}px);
                      color: ${lighterTextColor};
                    `}
                  >
                    {value}
                  </div>
                </label>
              </div>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButton;
