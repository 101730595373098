import QuestionText from "../../Text/QuestionText";
import { Fragment } from "react";

const ResourcesSlide = () => {
  return (
    <Fragment>
      <QuestionText center>
        It is really hard to relive your experience.
      </QuestionText>
      <p>You're not alone in not wanting to put yourself through that.</p>
      <p>Everyone is allowed to move at their own pace.</p>
      <br />
      <br />
      <br />
      <p style={{ fontWeight: "bold", fontSize: "1.25em" }}>
        If you need resources that might help you heal, try visiting:
      </p>
      <ul>
        <li>
          <a href="https://www.rainn.org/">RAINN</a>
        </li>
        <li>
          <a href="https://www.nsvrc.org/">
            National Sexual Violence Resource Center
          </a>
        </li>
        <li>
          <a href="https://herstory.global/">herstory</a>
        </li>
      </ul>
    </Fragment>
  );
};

export default ResourcesSlide;
