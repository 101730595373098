/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const QuestionText = (props) => {
  const textAlign = (() => {
    if (props.center) {
      return "center";
    }
    if (props.right) {
      return "right";
    } else {
      return "left";
    }
  })();
  const gridArea = props.gridArea ? `grid-area: ${props.gridArea};` : "";
  return (
    <p
      css={css`
        font-size: 2em;
        font-weight: bold;
        display: ${props.display};
        text-align: ${textAlign};
        margin: 0 0 0 0;
        line-height: 1.5;
        ${gridArea}
      `}
    >
      {props.children}
    </p>
  );
};

export default QuestionText;
