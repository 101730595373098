const reducerForAppState = (state, action) => {
  let newState;
  switch (action.type) {
    case "goForwardOneQuestion":
      newState = {
        currentQuestion: state.currentQuestion + 1,
        listOfPreviousQuestionsWeShowed: (() => {
          return [
            state.currentQuestion,
            ...state.listOfPreviousQuestionsWeShowed,
          ];
        })(),
        pointTotal: state.pointTotal,
      };
      break;
    case "skipForwardToASpecificQuestion":
      newState = {
        currentQuestion: action.skipToQuestion,
        listOfPreviousQuestionsWeShowed: (() => {
          return [
            state.currentQuestion,
            ...state.listOfPreviousQuestionsWeShowed,
          ];
        })(),
        pointTotal: state.pointTotal,
      };
      break;
    case "backButton":
      newState = {
        currentQuestion: state.listOfPreviousQuestionsWeShowed[0],
        listOfPreviousQuestionsWeShowed: [
          ...(() => {
            const currentState = state.listOfPreviousQuestionsWeShowed;
            return (([firstArrayItem, ...restOfArray]) => {
              return restOfArray;
            })(currentState);
          })(),
        ],
        pointTotal: state.pointTotal,
      };
      break;
    case "addPoints":
      newState = {
        currentQuestion: state.currentQuestion,
        listOfPreviousQuestionsWeShowed: [
          ...state.listOfPreviousQuestionsWeShowed,
        ],
        pointTotal: state.pointTotal + action.pointsToAdd,
      };
      break;
    case "addAnswers":
      let answersForUpdating = {};
      //Current question already has answers recorded
      if (state.answers[state.currentQuestion]) {
        //Questions that allow multiple answers
        if (typeof state.answers[state.currentQuestion] === Array) {
          answersForUpdating = [
            ...state.answers[state.currentQuestion],
            action.newAnswer,
          ];
        }
        //Single answer questions
        else answersForUpdating = action.newAnswer;
      }
      //Current question has no answers recorded
      else answersForUpdating = action.newAnswer;
      newState = {
        currentQuestion: state.currentQuestion,
        listOfPreviousQuestionsWeShowed: [
          ...state.listOfPreviousQuestionsWeShowed,
        ],
        pointTotal: state.pointTotal,
        answers: {
          ...state.answers,
          [state.currentQuestion]: answersForUpdating,
        },
      };
      break;
    default:
    //
  }
  return newState;
};

export default reducerForAppState;
