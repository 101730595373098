/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import HeaderText from "../Text/HeaderText";
import BulletedList from "../Text/BulletedList";
import ParagraphText from "../Text/ParagraphText";
import TwoColumnFlexBox from "../Text/TwoColumnFlexBox";
import AwardsBar from "../classlawgroup.com/gg-twentysixteen/glgreact/src/Awards Bar/AwardsBar";
import { useViewportWidth } from "../GlobalVariables";
import QuoteText from "../Text/QuoteText";
import SurvivorCaeli from "../Images/SurvivorCaeli.png"

const About = () => {
  const scaleYoutube = useViewportWidth().videoScale;
  return (
    <Fragment>
      <HeaderText>About Us</HeaderText>
      <ParagraphText alignment="justify">
        Gibbs Law Group’s award-winning sexual assault attorneys represent
        survivors across the country. Our team is committed to empowering sexual
        assault survivors to regain control, achieve justice and begin to heal.
        We recently obtained a $73 million settlement on behalf of former
        patients of UCLA gynecologist Dr. James Heaps, who was accused of sexual
        abuse and misconduct.
      </ParagraphText>

      <BulletedList
        alignment="justify"
        bulletedList={[
          <p>
            <strong>We believe you can heal.</strong> The journey may look
            different for everyone. But know this: what happened to you wasn’t
            your fault, and you aren’t alone.
          </p>,
          <p>
            <strong>We believe in your power.</strong> Sexual assault can be
            personal and traumatic, and we honor the courage and strength it
            takes to share your story. If you have questions, feel ready to
            pursue a legal claim, or just need someone to talk to about your
            experience, you can confide in our team of experienced
            professionals.
          </p>,
          <p>
            <strong>You’re in control.</strong> As your advocates, we’re
            dedicated to helping you gain your power back. You can choose to
            work with female or male attorneys and staff. Whether you prefer
            phone calls, videoconferencing or texting, we can communicate in a
            way that’s comfortable for you. You are in control and you decide
            what you want to do.
          </p>,
          <p>
            <strong>We’re with you, every step of the way.</strong> We’ll keep
            you updated on your case—in non-lawyer terms. We will explain the
            legal process and next steps in a clear way you can understand. Our
            client-centered approach is rooted in a sincere focus on our
            clients’ wellbeing and intended to empower clients’ pursuit of
            justice.
          </p>,
          <p>
            <strong>Our results are unmatched.</strong> Our award-winning
            lawyers take on some of the biggest, most powerful entities across
            the country—and win. We have the resources, experience and skill to
            fight for you and other survivors of sexual assault. Our attorneys
            have received numerous honors for their work, including “Top Women
            Lawyers in California,” “Top Plaintiff Lawyers in California,”
            “Consumer Protection MVP,” “Best Lawyers in America,” and “Class
            Action Practice Group of the Year.”
          </p>,
        ]}
      ></BulletedList>

      <AwardsBar noTitle />

      <HeaderText>Attorney Spotlight: Karen Barth Menzies</HeaderText>

      <TwoColumnFlexBox
        margin="2em 0 0 0"
        left={
          <iframe
            // justifyContent="center"
            width={`${scaleYoutube * 336}`}
            height={`${scaleYoutube * 189}`}
            src="https://www.youtube.com/embed/UqOgdGIqjP4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        }
        right={
          <Fragment>
            <p
              css={css`
                text-align: justify;
                font-size: 0.9em;
              `}
            >
              Karen represents survivors throughout the country and leads our
              sexual assault practice group. She has been recognized as a Super
              Lawyer in Southern California for the last fifteen years. She is
              ready to listen to your story, without judgment. She’s on your
              side.
            </p>
            <p
              css={css`
                text-align: justify;
                font-size: 0.9em;
              `}
            >
              Karen has over two decades of litigation experience in some of the
              nation’s most complex cases, and she believes in advocating for
              the victims who’ve been taken advantage of. She currently
              represents former members of Boy Scouts of America in claims of
              sexual abuse by scoutmasters, troop leaders and other adults. She
              also represents survivors who experienced Catholic Clergy sexual
              abuse in claims against the Archdiocese of New Orleans.
            </p>
          </Fragment>
        }
      ></TwoColumnFlexBox>

      <TwoColumnFlexBox
        margin="2em 0 0 0"
        left={
          <Fragment>
            <QuoteText><strong>“Gibbs Law Group has provided a foundation of safety, trauma-informed support and understanding</strong> as I seek to hold my abuser accountable. Working with Karen and Jeff has empowered other survivors and I to stand up against the pervasive rape culture of the music industry.”<br/>—Michaela H.</QuoteText>
          </Fragment>
        }
        right={
          <img
            id="caeli-image"
            src={SurvivorCaeli}
            css={css`
              justify-self: flex-end;
              padding: 1em;
              height: 10em;
            `}
            alt="Caeli quote"
          />
        }
      ></TwoColumnFlexBox>

    </Fragment>
    //INSERT AWARDS FROM classlawgroup SITE
  );
};

export default About;
