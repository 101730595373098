/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useViewportWidth } from "../GlobalVariables";

const TwoColumnFlexBox = (props) => {
  const { flexWrap } = useViewportWidth();
  const leftSideColumn = props.left;
  const rightSideColumn = props.right;
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: ${flexWrap};
        justify-content: center;
        align-items: center;
        margin: ${props.margin || ""};
        gap: ${flexWrap === "nowrap" ? "3em" : "1em"};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {leftSideColumn}
      </div>
      <div css={css``}>{rightSideColumn}</div>
    </div>
  );
};
export default TwoColumnFlexBox;
