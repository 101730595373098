/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const ProgressText = (props) => {
  return (
    <p
      css={css`
        color: gray;
        opacity: 0.75;
        padding-left: 1em;
      `}
    >
      {props.children}
    </p>
  );
};

export default ProgressText;
