/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const BoxText = (props) => {
  return (
    <p
      css={css`
        color: ${props.color};
        font-size: 0.8em;
        font-style: italic;
        margin-top: 0;
        text-align: center;
        margin-left: 1em;
        margin-right: 1em;
        line-height: 1.5;
      `}
    >
      {props.children}
    </p>
  );
};

export default BoxText;
