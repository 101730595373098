/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Variables
import { useViewportWidth } from "./GlobalVariables";
//JSX
import Navbar from "./Navigation/Navbar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Footer from "./UI/Footer";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( App.js HANDLES JUST THE ROUTING (WHICH PAGE TO DISPLAY?) AND THE OVERALL SITE MARGINS/GRID)))
//
// For the next level down, check out the "Pages" folder (or in the below code, check out what is in the relevant <Route element={<Component/>}> )
const App = () => {
  const [theCurrentPage, setTheCurrentPage] = useState();
  const { websiteGutter } = useViewportWidth();
  const minimumGutterWidth = 2.5;
  const maximumGutterWidth = 34;
  const handlePass = (value) => {
    setTheCurrentPage(value);
  };
  return (
    <BrowserRouter>
      <Navbar passToParent={handlePass} />
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 8fr 1fr;
          grid-template-areas: "leftGutter mainContentArea rightGutter";
          line-height: 2;
        `}
      >
        <div
          id="leftGutter"
          css={css`
            grid-area: leftGutter;
            width: clamp(
              ${minimumGutterWidth}vw,
              ${websiteGutter},
              ${maximumGutterWidth}vw
            );
          `}
        ></div>
        <div
          id="mainContentArea"
          css={css`
            grid-area: mainContentArea;
            width: clamp(
              ${100 - maximumGutterWidth * 2}vw,
              ${100 - websiteGutter * 2}vw,
              ${100 - minimumGutterWidth * 2}vw
            );
          `}
        >
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about/" element={<About />}></Route>
          </Routes>
        </div>
        <div
          id="rightGutter"
          css={css`
            grid-area: rightGutter;
            width: clamp(
              ${minimumGutterWidth}vw,
              ${websiteGutter},
              ${maximumGutterWidth}vw
            );
          `}
        ></div>
      </div>
      {/* // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // ((( SHOW FOOTER IF THE CURRENT PAGE IS THE HOME PAGE )))
      // */}
      {theCurrentPage === "Home" && (
        <Footer center>
          <p
            css={css`
              font-weight: bold;
            `}
          >
            Your answers are private and will not be stored or shared with
            anyone.
          </p>
          <p>
            You’ll also have the option to skip sections and we encourage you to
            go at a pace that is comfortable for you.
          </p>
        </Footer>
      )}
      {/* // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
    </BrowserRouter>
  );
};

export default App;
