/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const FlexBreak = () => {
  return (
    <div
      css={css`
        flex-basis: 100%;
        height: 0;
      `}
    ></div>
  );
};

export default FlexBreak;
