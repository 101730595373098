/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import { useState } from "react";

import {
  textColor,
  lighterTextColor,
  answerChoicePadding,
} from "../../GlobalVariables";
import CheckboxItem from "./CheckboxItem";

// I need to change Checkbox to use state instead of built-in (uncontrolled) <input type="checkbox"/> functionality in order to have it get its answer from local storage, i.e. go to the About page and then still have answers saved.
// Unrelatedly, to leave and come back, I also need to save currentQuestionNumber and use that from local storage.
// Also, if we want to live up to the promise that your data is not stored, we would want to switch to using sessionStorage and not localStorage, which persists across tabs and browser sessions.

const Checkbox = (props) => {
  // const [showCheck, setShowCheck] = useState(false);
  // const [theEvent, setTheEvent] = useState();
  const fontColor = textColor;
  const lighterColor = lighterTextColor;
  // const changeHandler = () => {
  //   setShowCheck((prevState) => !prevState);
  // };
  const dimensions = "30px";
  return (
    <section
      style={{ display: props.display }}
      className={props.display !== "none" ? "glg-animate-in" : ""}
    >
      <style>{`
        .glg-animate-in {
          animation: glg-animate-slide-in 0.25s ease-in;
        }
        @keyframes glg-animate-slide-in {
          0% {
            transform: translate(-10em, -2.5em);
          }
          100% {
            transform: none;
          }
        }
        .ac-custom {
            max-width: 900px;
        }
        .ac-custom, .ac-custom *, .ac-custom *:after, .ac-custom *::before {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        form.ac-custom {
            display:block;
        }
        .ac-custom ul, .ac-custom ol {
            list-style: none;
            max-width: 800px;
            padding: 0;
        }
        .ac-custom li {
            padding: 0 ${answerChoicePadding};
            position: relative;
        }
        .ac-custom li:first-of-type {
          // padding-top: 1em;
        }
        .ac-custom input[type="checkbox"], .ac-custom input[type="radio"] {
            opacity: 0;
            -webkit-appearance: none;
            display: inline-block;
            vertical-align: middle;
            z-index: 100;
        }
        .ac-custom label::before {
            content: '';
            border: 4px solid ${fontColor};
            -webkit-transition: opacity 0.3s;
            transition: opacity 0.3s;
        }
        .ac-radio label::before {
            border-radius: 50%;
        }
        .ac-custom input[type="checkbox"], .ac-custom input[type="radio"], .ac-custom label::before {
            width: ${dimensions};
            height: ${dimensions};
            top: 50%;
            left: 0;
            margin-top: -25px;
            position: absolute;
            cursor: pointer;
        }
        .ac-custom label {
            display: inline-block;
            position: relative;
            font-size: 1.5em;
            padding: 0 0 20px 55px;
            color: ${lighterColor};
            cursor: pointer;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
        }
        .ac-custom input[type="checkbox"]:checked + label,
        .ac-custom input[type="radio"]:checked + label {
            color: ${fontColor};
            font-weight: 450;
        } 
        .ac-custom input[type="checkbox"]:checked + label::before,
        .ac-custom input[type="radio"]:checked + label::before {
            opacity: 0.9;
        }
        `}</style>
      {/* remove reliance on the form, or change the "checked" value on the input in CheckboxItem */}
      <form
        className="ac-custom ac-checkbox ac-checkmark"
        autoComplete="off"
        css={css`
          margin: 0;
          padding: 0;
        `}
      >
        {props.secondaryText ? (
          <p
            css={css`
              color: ${lighterTextColor};
              font-size: 1.25em;
              padding: 0.5em;
            `}
          >
            {props.secondaryText}
          </p>
        ) : null}
        <ul>
          {props.answerChoices.map((answer, index) => {
            return (
              <CheckboxItem
                key={`CheckboxItem-question${props.QuestionNumber}-answer${index}`}
                parentFunction={props.onChange}
                questionNumber={props.questionNumber}
                answer={answer}
                uncheckAnimation
              />
            ); // add a "uncheckAnimation" props (with no equals) to <CheckboxItem/> to toggle on the uncheck animation
          })}
        </ul>
      </form>
    </section>
  );
};

export default Checkbox;
