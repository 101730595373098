/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const SubHeading = (props) => {
  return (
    <p
      css={css`
        grid-area: ${props.gridArea};
        font-size: 1.25em;
        font-style: italic;
        text-align: center;
        margin-top: 2.5em;
        margin-bottom: 2.5em;
      `}
    >
      {props.children}
    </p>
  );
};

export default SubHeading;
