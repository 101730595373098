/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const FinishSVG = (props) => {
  const scaledDimensions = (+props.scale * 512).toString();
  return (
    <svg
      height={scaledDimensions}
      width={scaledDimensions}
      viewBox="-12 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        opacity: ${props.opacity || "1"};
      `}
    >
      <path
        d="m477.40625 194.53125h-26.070312c-.289063 0-.574219-.019531-.859376-.042969v304.953125c0 6.90625 5.628907 12.558594 12.511719 12.558594h2.765625c6.882813 0 12.511719-5.652344 12.511719-12.558594v-304.953125c-.28125.023438-.570313.042969-.859375.042969zm0 0"
        fill="#c7d2e5"
      />
      <path
        d="m35.976562 194.53125h-26.070312c-.289062 0-.578125-.019531-.859375-.042969v304.953125c0 6.90625 5.628906 12.558594 12.511719 12.558594h2.765625c6.878906 0 12.511719-5.652344 12.511719-12.558594v-304.953125c-.285157.023438-.570313.042969-.859376.042969zm0 0"
        fill="#c7d2e5"
      />
      <path
        d="m451.335938 37.542969h26.070312c.289062 0 .578125.019531.859375.046875v-24.71875c.003906-7.078125-5.628906-12.871094-12.511719-12.871094h-2.765625c-6.882812 0-12.511719 5.792969-12.511719 12.871094v24.71875c.285157-.027344.570313-.046875.859376-.046875zm0 0"
        fill="#c7d2e5"
      />
      <path
        d="m9.90625 37.542969h26.070312c.289063 0 .574219.019531.859376.046875v-24.71875c0-7.078125-5.632813-12.871094-12.511719-12.871094h-2.765625c-6.882813 0-12.511719 5.792969-12.511719 12.871094v24.71875c.28125-.027344.566406-.046875.859375-.046875zm0 0"
        fill="#c7d2e5"
      />
      <path
        d="m444.285156 185.789062v-139.386718c0-.851563.121094-1.671875.328125-2.460938-52.648437 10.898438-123.554687 17.582032-201.582031 17.582032-78.03125 0-148.9375-6.683594-201.585938-17.582032.207032.789063.332032 1.609375.332032 2.460938v139.382812c0 .714844-.082032 1.40625-.230469 2.078125 52.636719 10.886719 123.503906 17.5625 201.484375 17.5625 77.976562 0 148.84375-6.675781 201.484375-17.5625-.148437-.667969-.230469-1.363281-.230469-2.074219zm0 0"
        fill="#ff6b6b"
      />
      <path
        d="m41.546875 187.863281c10.609375 2.195313 21.96875 4.214844 33.964844 6.046875v-143.902344c-12.035157-1.839843-23.429688-3.867187-34.066407-6.066406.207032.789063.332032 1.609375.332032 2.460938v139.386718c0 .710938-.082032 1.40625-.230469 2.074219zm0 0"
        fill="#ee5253"
      />
      <path
        d="m477.40625 36.496094h-26.070312c-5.445313 0-9.902344 4.460937-9.902344 9.90625v139.382812c0 5.449219 4.457031 9.90625 9.902344 9.90625h26.070312c5.449219 0 9.90625-4.457031 9.90625-9.90625v-139.382812c0-5.445313-4.457031-9.90625-9.90625-9.90625zm0 0"
        fill="#ff6b6b"
      />
      <path
        d="m35.976562 36.496094h-26.070312c-5.449219 0-9.90625 4.460937-9.90625 9.90625v139.382812c0 5.449219 4.457031 9.90625 9.90625 9.90625h26.070312c5.445313 0 9.90625-4.457031 9.90625-9.90625v-139.382812c0-5.445313-4.460937-9.90625-9.90625-9.90625zm0 0"
        fill="#ff6b6b"
      />
      <path
        d="m468.832031 185.789062v-139.386718c0-5.222656 4.101563-9.523438 9.242188-9.871094-.222657-.015625-.441407-.035156-.667969-.035156h-26.070312c-5.445313 0-9.90625 4.460937-9.90625 9.90625v139.386718c0 5.445313 4.460937 9.90625 9.90625 9.90625h26.070312c.226562 0 .445312-.019531.664062-.035156-5.136718-.347656-9.238281-4.648437-9.238281-9.871094zm0 0"
        fill="#ee5253"
      />
      <path
        d="m27.402344 185.789062v-139.386718c0-5.222656 4.097656-9.523438 9.238281-9.871094-.21875-.015625-.441406-.035156-.664063-.035156h-26.070312c-5.449219 0-9.90625 4.460937-9.90625 9.90625v139.386718c0 5.445313 4.457031 9.90625 9.90625 9.90625h26.070312c.222657 0 .445313-.019531.664063-.035156-5.136719-.347656-9.238281-4.648437-9.238281-9.871094zm0 0"
        fill="#ee5253"
      />
      <g fill="#c7d2e5">
        <path d="m101.640625 89.234375c.390625-3.515625 4.320313-4.859375 8.054687-4.445313l38.121094 4.230469c3.625.402344 4.734375 4.417969 4.347656 7.933594-.453124 4.0625-2.710937 7.371094-6.003906 7.007813l-28.453125-3.15625-2.0625 18.5625 16.589844 1.84375c3.296875.363281 4.8125 3.757812 4.421875 7.273437-.332031 2.964844-2.257812 6.308594-5.882812 5.90625l-16.589844-1.839844-3.144532 28.339844c-.390624 3.515625-4.867187 4.796875-9.152343 4.324219-4.285157-.476563-8.375-2.707032-7.984375-6.222656zm0 0" />
        <path d="m157.167969 94.746094c.253906-3.640625 4.660156-4.882813 8.960937-4.585938 4.296875.300782 8.492188 2.140625 8.238282 5.78125l-4.875 70.128906c-.246094 3.527344-4.671876 4.992188-8.972657 4.695313-4.296875-.300781-8.476562-2.363281-8.230469-5.890625zm0 0" />
        <path d="m221.335938 168.914062-19.867188-38.851562-1.105469 37.566406c-.105469 3.535156-4.46875 5.175782-8.777343 5.050782-4.308594-.128907-8.566407-2.023438-8.460938-5.558594l2.070312-70.160156c.109376-3.648438 4.464844-5.175782 8.773438-5.050782 6.1875.183594 8.492188.804688 12.453125 8.992188l16.890625 34.671875 1.109375-37.566407c.109375-3.644531 4.460937-5.066406 8.773437-4.9375 4.308594.125 8.570313 1.800782 8.464844 5.449219l-2.074218 70.269531c-.105469 3.535157-4.464844 5.175782-8.777344 5.050782-3.976563-.121094-7.488282-1-9.472656-4.925782zm0 0" />
        <path d="m252.289062 98.269531c-.027343-3.648437 4.273438-5.226562 8.585938-5.253906 4.308594-.03125 8.632812 1.484375 8.65625 5.132813l.496094 70.300781c.027344 3.539062-4.273438 5.335937-8.582032 5.367187-4.3125.03125-8.636718-1.707031-8.660156-5.246094zm0 0" />
        <path d="m315.878906 150.691406c-.546875-13.363281-35.460937-9.613281-36.515625-35.457031-.679687-16.566406 13.53125-23.453125 27.335938-24.015625 5.851562-.242188 22.023437.203125 22.375 8.816406.121093 2.984375-1.617188 9.136719-6.476563 9.339844-3.976562.160156-6.246094-3.949219-15.300781-3.578125-7.84375.320313-10.914063 3.652344-10.773437 7.078125.449218 11.042969 35.375 7.511719 36.511718 35.34375.652344 15.902344-10.589844 24.992188-26.605468 25.648438-14.46875.589843-26.90625-5.980469-27.203126-13.269532-.15625-3.753906 2.933594-9.414062 7.128907-9.585937 5.191406-.210938 8.839843 7.828125 19.109375 7.40625 5.082031-.207031 10.632812-2.425781 10.414062-7.726563zm0 0" />
        <path d="m380.851562 132.082031-21.894531 2.121094 2.890625 29.8125c.339844 3.523437-3.78125 5.699219-8.070312 6.113281-4.289063.417969-8.75-.929687-9.09375-4.449218l-6.777344-69.964844c-.351562-3.632813 3.789062-5.589844 8.078125-6.003906 4.289063-.414063 8.730469.707031 9.082031 4.339843l2.609375 26.953125 21.894531-2.121094-2.613281-26.953124c-.351562-3.632813 3.789063-5.589844 8.082031-6.003907 4.289063-.414062 8.730469.707031 9.078126 4.339844l6.78125 69.964844c.339843 3.523437-3.777344 5.699219-8.070313 6.113281-4.289063.417969-8.75-.925781-9.089844-4.449219zm0 0" />
      </g>
    </svg>
  );
};

export default FinishSVG;
