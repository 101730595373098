/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AppButton from "../Buttons/AppButton";
import { useEffect, useState, useReducer, Fragment } from "react";
import QuestionText from "../Text/QuestionText";
import FrontPage from "../StaticSlides/BeginningSlides/FrontPage";
import ResourcesSlide from "../StaticSlides/EndingSlides/ResourcesSlide";
import GetQuestionByType from "./GetQuestionByType";
import reducerForAppState from "./reducerForAppState";
import EndBuckets from "../StaticSlides/EndingSlides/FinalScore/EndBuckets";
import SubmitSurvey from "../StaticSlides/EndingSlides/FinalScore/SubmitSurvey";
import {
  pointsPerAnswer,
  useViewportWidth,
  buttonColor,
  urlColor,
  debug,
} from "../GlobalVariables";
import FlexBreak from "../UI/FlexBreak";
import AbuseTransitionSlide from "../StaticSlides/TransitionSlides.js/AbuseTransitionSlide";
import StopHandSVG from "../StaticSlides/TransitionSlides.js/Section 2 - The Abuse/StopHandSVG";
import HeadphonesSVG from "../StaticSlides/TransitionSlides.js/Section 1 - Music Industry/HeadphonesSVG";
import SuitAndQuestionMarkSVG from "../StaticSlides/TransitionSlides.js/Section 3 - The Abuser/SuitAndQuestionMarkSVG";
import TimeMovesOnSVG from "../StaticSlides/TransitionSlides.js/Section 4 - Aftermath/TimeMovesOnSVG";
import AllFourSectionTransition from "../StaticSlides/TransitionSlides.js/Preview All Four Sections/AllFourSectionTransition";
import ProgressText from "../ProgressBar/ProgressText";
import FinishSVG from "../ProgressBar/FinishSVG";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( SPECIFY THE STARTING SLIDE NUMBER )))
// Here, we need to specify a starting slide other than 1 because the app needs to show several slides (and chute options) before we get to the survey questions that start at 1. The more slides we add before the survey, the further back we will have to push the starting slide number.
const startingSlide = -1;
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( SPECIFY THE MARGIN IN A SQUARE AROUND THE BACK & NEXT BUTTONS )))
//
const theButtonMargin = "1.75em";
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( SKIP LANGUAGE )))
// Language for the transition slides saying you can skip the section if you need to.
const skipSectionText = `If you're concerned this section might be triggering, please feel free to "skip section" below.`;
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

const SASApp = (props) => {
  const { appGutterWidth } = useViewportWidth();
  const appGutter = `1vw, ${appGutterWidth}, 5vw`;
  const [points, setPoints] = useState(0);
  const lastQuestion = 43;

  //TESTING FOR POINTS
  const functionToCalculatePoints = () => {
    let pointTotal = 0;
    let previousQuestions = appState.listOfPreviousQuestionsWeShowed;
    for (let questionNumber of previousQuestions) {
      let answerChoice = answers[questionNumber];
      if (debug) console.log("inside first for loop");
      if (answerChoice) {
        if (debug) console.log("type of answer:", typeof answerChoice);
        if (typeof answerChoice === "string") {
          pointTotal += pointsPerAnswer[questionNumber][answerChoice];
          if (debug) {
            console.log(
              "points for string:",
              pointsPerAnswer[questionNumber][answerChoice]
            );
          }
        } else {
          let totalPerQuestion = 0;
          for (let eachAnswer of answerChoice) {
            totalPerQuestion += pointsPerAnswer[questionNumber][eachAnswer];
            if (debug) {
              console.log(eachAnswer);
              console.log(pointsPerAnswer[questionNumber]);
              console.log(pointsPerAnswer[questionNumber][eachAnswer]);
              console.log("total per question:", totalPerQuestion);
            }
          }
          const maxPerQuestion = pointsPerAnswer[questionNumber]["maximum"];
          if (totalPerQuestion > maxPerQuestion) {
            pointTotal += maxPerQuestion;
          } else {
            pointTotal += totalPerQuestion;
          }
        }
      }
    }
    setPoints(pointTotal);
    return pointTotal;
  };

  const theQuestions = props.questions;
  const functionToGenerateUrl = () => {
    let answerUrl =
      "https://surveys.classlawgroup.com/sexual-assault-intake-form/?survey=";
    let questionTextArray = [];
    if (debug) console.log("inside url fxn answers:", answers);
    let answerKeys = Object.keys(answers); // use the keys to get question text
    let allAnswers = Object.values(answers); // set aside all survey answers
    if (debug) console.log(theQuestions, " is questions");

    //use keys from props.answers to find the right question text for each answer
    answerKeys.forEach((eachKey) => {
      const questionIndex = theQuestions.findIndex(
        (eachQuestion) =>
          eachQuestion.questionNumber.toString() === eachKey.toString()
      );
      questionTextArray.push(theQuestions[questionIndex].questionText);
    });

    allAnswers.forEach((eachAnswer, i) => {
      var urlText = questionTextArray[i].toString().replace(/ /g, "%20");
      var urlAnswer = eachAnswer.toString().replace(/ /g, "%20");
      answerUrl = answerUrl + "|" + urlText + "%20" + urlAnswer;
      if (debug) console.log(answerUrl, " answer url thing");
    });

    return answerUrl;
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( GET WIDTH FOR MAIN APP AREA )))
  // Here, we use a custom hook we are importing from GlobalVariables.js, and we use object destructuring to get the only width we care about. The custom hook measures the total viewport width and figures out how big the main app area and the gutters should be, and it returns an object whose values are in "vw", which is a CSS unit of measurement that represents a percentage of the total viewport width. For example, 50vw should be 50% of your screen's total width.
  const { mainAppAreaWidth } = useViewportWidth();
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( STATE FOR STORING ANSWERS )))
  //
  // This state is used so that when someone hits the back button, they will see previous answers, and when they go forward again, they will see answers they already entered.
  const [answers, setAnswers] = useState({
    ...JSON.parse(window.sessionStorage.getItem("answers")),
  });
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( STATE AND FUNCTION FOR DISABLING BUTTON )))
  //
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [chuteState, setChuteState] = useState();
  const unDisableButton = (childState) => {
    setChuteState(childState);
    const childStateValues = Object.values(childState);
    for (let each of childStateValues) {
      if (each) {
        setButtonDisabled(false);
        return;
      }
    }
    setButtonDisabled(true);
  };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( OVERALL APP STATE VIA useReducer )))
  //
  // This useReducer stores: the current question number, running list of previous questions the user has answered (to enable back button functionality), running tally of the point total, and list of all answers.
  const [appState, dispatchAppState] = useReducer(reducerForAppState, {
    currentQuestion: startingSlide,
    listOfPreviousQuestionsWeShowed: [],
    pointTotal: 0,
    answers: { ...JSON.parse(window.sessionStorage.getItem("answers")) },
  });
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( HELPER FUNCTIONS )))
  //
  const getCurrentAnswer = (canBeMultipleAnswers) => {
    const theCurrentAnswer = answers[appState.currentQuestion];
    return canBeMultipleAnswers && !theCurrentAnswer ? [] : theCurrentAnswer;
  };
  const getAnswerForQuestion = (number, canBeMultipleAnswers) => {
    const theAnswer = answers[number];
    return canBeMultipleAnswers && !theAnswer ? [] : theAnswer;
  };
  const goToNextQuestion = () => {
    (() => dispatchAppState({ type: "goForwardOneQuestion" }))();
  };
  const skipToQuestion = (number) => {
    (() =>
      dispatchAppState({
        type: "skipForwardToASpecificQuestion",
        skipToQuestion: number,
      }))();
  };
  const goBack = () => {
    (() =>
      dispatchAppState({
        type: "backButton",
      }))();
  };
  // const addAnswers = (newAnswer) => {
  //   (() => {
  //     dispatchAppState({
  //       type: "addAnswers",
  //       newAnswer: newAnswer,
  //     });
  //   })();
  // };
  // const addPoints = (pointsArray) => {
  //   //get answer on current slide

  //   (() => {
  //     dispatchAppState({
  //       type: "addPoints",
  //       //need to fix the below with a variable
  //       pointsToAdd: 1,
  //     });
  //   })();
  // };
  const doLogic = (otherConditions, questionToSkipTo, moreLogic = "") => {
    if (otherConditions) {
      skipToQuestion(questionToSkipTo);
    } else {
      // If there is more logic to evaluate, return (exit this function) rather than going to the next question.
      if (moreLogic) return "conditions not satisfied";
      if (!moreLogic) goToNextQuestion();
    }
  };
  // const getAnswerForQuestion = (number) => {
  //   return answers[number];
  // };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( FUNCTION FOR CLICKING THE FIRST BUTTON )))
  // Conditionally goes down the correct chute when first button is clicked.
  const getStartedClicked = () => {
    if (buttonDisabled) return;
    const childStateKeys = Object.keys(chuteState);
    for (let each of childStateKeys) {
      if (chuteState[each]) {
        switch (each) {
          case "dot1":
            skipToQuestion(-100);
            break;
          case "dot2":
            window.location.href =
              "https://www.classlawgroup.com/sexual-assault-civil-suits/#the-form";
            break;
          case "dot3":
            skipToQuestion(lastQuestion + 2);
            break;
          default:
            return;
        }
      }
    }
  };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( FUNCTION FOR WHEN ANY OTHER BUTTON IS CLICKED )))
  //
  // Figure out which button it is (e.g. next vs. back button), then apply question logic if any exists. If no logic exists, go to next question (current question + 1).
  const whenButtonIsClicked = (isNextButton) => {
    if (isNextButton === "next") {
      //figure out which question to go to next, and go to it
      switch (appState.currentQuestion) {
        case 1:
          doLogic(getCurrentAnswer() === "Yes", 3);
          break;
        case 2:
          skipToQuestion(7);
          break;
        case 3:
          // if skipped asks if still working in industry
          if (getCurrentAnswer() === undefined) {
            skipToQuestion(5);
          } else {
            doLogic(
              !getCurrentAnswer("canBeMultipleAnswers").includes("Other"),
              5
            );
          }
          break;
        case 5:
          // always skip question 6 because we deleted the old question 6 and now it's supposed to come at the end of the questionnaire.
          skipToQuestion(7);
          break;
        case 6:
          // If the person answered "Police" and "Other" to question 38, then go back and ask what the "Other" person was. Otherwise, go to the end of the questionnaire.
          console.log(getAnswerForQuestion(38, "canBeMultipleAnswers"));
          const logicFor6 = doLogic(
            getAnswerForQuestion(38, "canBeMultipleAnswers").includes("Other"),
            39,
            "more logic"
          );
          if (logicFor6 === "conditions not satisfied") skipToQuestion(43);
          break;
        case 8:
          doLogic(
            !getCurrentAnswer("canBeMultipleAnswers").includes("Other"),
            10
          );
          break;
        case 10:
          // if skipped jumps to age at time of abuse
          if (getCurrentAnswer() === undefined) {
            skipToQuestion(17);
          } else {
            doLogic(getCurrentAnswer() === "More than once", 13);
          }
          break;
        case 12:
          skipToQuestion(17);
          break;
        case 19:
          // if skipped jumps to aftermath of abuse section
          if (getCurrentAnswer() === undefined) {
            skipToQuestion(34);
          } else {
            doLogic(getCurrentAnswer() === "Multiple people", 27);
          }
          break;
        case 20:
          // if skipped asks if perpetrator worked in music industry
          doLogic(getCurrentAnswer() !== "Yes", 22);
          break;
        case 22:
          doLogic(getCurrentAnswer() === "Yes", 24);
          break;
        case 23:
          skipToQuestion(34);
          break;
        case 24:
          doLogic(
            !getCurrentAnswer("canBeMultipleAnswers").includes("Other"),
            26
          );
          break;
        case 26:
          skipToQuestion(40);
          break;
        case 27:
          // if skipped asks if perpetrators worked in music industry
          doLogic(getCurrentAnswer() !== "Yes", 29);
          break;
        case 29:
          doLogic(getCurrentAnswer() === "Yes", 31);
          break;
        case 30:
          skipToQuestion(34);
          break;
        case 31:
          doLogic(
            !getCurrentAnswer("canBeMultipleAnswers").includes("Other"),
            40
          );
          break;
        case 34:
          doLogic(
            getAnswerForQuestion(20) === "No", 37
          );
          // doLogic(
          //   getAnswerForQuestion(20) === "yes", 35
          // );
          break;
        case 35:
          doLogic(
            !getCurrentAnswer("canBeMultipleAnswers").includes("Other"),
            37
          );
          break;
        case 37:
          // if skipped jumps to end
          doLogic(getCurrentAnswer() !== "Yes", 42);
          break;
        case 38:
          // If the person told police, skips to question 6 about whether there was a prosecution or police report. If "Other" is selected and not "Police", then goes to the next question (about who the "Other" person was). If the answer doesn't include "Police" or "Other", skips to the end of the questionnaire.
          const includesPolice = getCurrentAnswer(
            "canBeMultipleAnswers"
          ).includes("Police");
          doLogic(includesPolice, 6, "more logic");
          if (!includesPolice) {
            doLogic(
              !getCurrentAnswer("canBeMultipleAnswers").includes("Other"),
              42
            );
          }
          break;
        // case lastQuestion + 1:
        //   skipToQuestion(1);
        //   break;
        case -100:
          skipToQuestion(100);
          break;
        case 100:
          skipToQuestion(1);
          break;
        //if the question has no logic, then "default" gets triggered
        default:
          goToNextQuestion();
      }
    } else {
      //if the button is not "Next", it's the "Back" button, so go back to the previous question
      goBack();
    }
  };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( VARIABLES THAT SAY WHETHER TO HIDE THE BACK OR NEXT BUTTONS )))
  //
  const hideBackButton =
    appState.currentQuestion !== 100 &&
    appState.currentQuestion !== -100 &&
    (appState.currentQuestion <= startingSlide + 1 ||
      appState.currentQuestion > lastQuestion + 2);
  const hideNextButton =
    appState.currentQuestion === startingSlide ||
    appState.currentQuestion === startingSlide + 1 ||
    (appState.currentQuestion > lastQuestion + 1 &&
      appState.currentQuestion !== 100) ||
    appState.currentQuestion === 42 ||
    appState.currentQuestion === 43;
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( VARIABLES THAT SAY WHICH SECTIONS SHOULD SHOW WHAT CONTENT )))
  //
  const showForSection1 =
    appState.currentQuestion >= 1 && appState.currentQuestion <= 5;
  const showForSection2 =
    appState.currentQuestion >= 8 && appState.currentQuestion <= 17;
  const showForSection3 =
    appState.currentQuestion === 40 ||
    appState.currentQuestion === 41 ||
    (appState.currentQuestion >= 19 && appState.currentQuestion <= 33);
  const showForSection4 =
    appState.currentQuestion === 6 ||
    (appState.currentQuestion >= 35 && appState.currentQuestion <= 39);
  const showViewMyReportSlide = appState.currentQuestion === 42;
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( HANDLER FUNCTION FOR WHENEVER THE USER SELECTS OR CHANGES AN ANSWER )))
  //

  const handleChildChange = (event, questionNumber, questionType) => {
    // if not "checkbox", then there's no opportunity for multiple answers, so overwrite any answer that's already in the "answers" state
    if (questionType !== "checkbox") {
      setAnswers((prevState) => {
        window.sessionStorage.setItem(
          "answers",
          JSON.stringify({
            ...prevState,
            [questionNumber]: event,
          })
        );
        return { ...prevState, [questionNumber]: event };
      });
    }
    // if questionType === "checkbox", we can't simply overwrite whatever is already there, because "checkbox" allows multiple answers
    else {
      setAnswers((prevState) => {
        // Put the event received from handleChildChange (the GetQuestionByType component) into plain English
        const answerThatChanged = event[0];
        const wasUncheckedEvent = event[1] === "checked" ? false : true;

        // New checkbox got checked
        if (!wasUncheckedEvent) {
          // "answers" state already contains answers for this question
          if (prevState[questionNumber]) {
            // check if sessionStorage already contains this answer (additional safeguard)
            window.sessionStorage.setItem(
              "answers",
              JSON.stringify({
                ...prevState,
                [questionNumber]: [
                  ...prevState[questionNumber],
                  answerThatChanged,
                ],
              })
            );
            return {
              ...prevState,
              [questionNumber]: [
                ...prevState[questionNumber],
                answerThatChanged,
              ],
            };
          }
          // "answers" state does not already contain answers for this question
          else {
            window.sessionStorage.setItem(
              "answers",
              JSON.stringify({
                ...prevState,
                [questionNumber]: [answerThatChanged],
              })
            );
            return {
              ...prevState,
              [questionNumber]: [answerThatChanged],
            };
          }
        }
        // The answerThatChanged was unchecked, not checked
        else {
          window.sessionStorage.setItem(
            "answers",
            JSON.stringify({
              ...prevState,
              [questionNumber]: prevState[questionNumber].filter(
                (e) => e !== answerThatChanged // [Artist, Other]
              ),
            })
          );
          return {
            ...prevState,
            [questionNumber]: prevState[questionNumber].filter(
              (e) => e !== answerThatChanged
            ),
          };
        }
      });
    }
  };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( LOG TO THE CONSOLE )))
  //
  useEffect(() => {
    if (debug) {
      console.log("answers:", answers);
      console.log("answer keys: ", Object.keys(answers));
    }
  });
  useEffect(() => {
    if (debug) {
      console.log("current question:", appState.currentQuestion);
    }
  }, [appState.currentQuestion]);
  useEffect(() => {
    if (debug) {
      console.log(
        "previous questions:",
        appState.listOfPreviousQuestionsWeShowed
      );
    }
  }, [appState.listOfPreviousQuestionsWeShowed]);
  useEffect(() => {
    if (debug) console.log("Point total: ", appState.pointTotal);
  }, [appState.pointTotal]);

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( ADD QUESTIONS BASED ON TYPE )))
  //
  // Get and add the code for the question based on the question type specified in App.js

  const addQuestions = () => {
    let returnArray = [];
    props.questions.forEach((each) => {
      returnArray.push(
        <Fragment
          key={`Fragment-${each.questionType}-questionNumber${each.questionNumber}`}
        >
          <QuestionText
            key={`QuestionText-${each.questionType}-questionNumber${each.questionNumber}`}
            display={
              appState.currentQuestion === each.questionNumber
                ? "block"
                : "none"
            }
          >
            {each.questionText}
          </QuestionText>
          {/* {each.secondaryText ? (
            <p
              css={css`
                color: ${lighterTextColor};
                font-size: 1.25em;
                padding: 0.5em;
              `}
            >
              {each.secondaryText}
            </p>
          ) : null} */}
          <div
            key={`QuestionByTypeWrapperDiv-${each.questionType}-questionNumber${each.questionNumber}`}
            style={{ display: "block" }}
          >
            <GetQuestionByType
              key={`GetQuestionByType-${each.questionType}-questionNumber${each.questionNumber}`}
              question={each}
              currentQuestion={appState.currentQuestion}
              handleChildChange={handleChildChange}
              skipToQuestions={
                each.skipToQuestions ? [...each.skipToQuestions] : null
              }
              skipToQuestion={skipToQuestion}
            />
          </div>
        </Fragment>
      );
    });
    return returnArray;
  };
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // ((( JSX RETURNED BY THIS COMPONENT )))
  //
  return (
    <div
      className="SASApp"
      css={css`
        display: grid;
        grid-template-columns: 1fr 16fr 1fr;
        grid-template-areas:
          "headerLeft progressBar headerRight"
          "emptyLeft questions emptyRight"
          "emptyLeft2 navButtonArea emptyRight2";
      `}
    >
      <div
        css={css`
          grid-area: headerLeft;
          width: clamp(${appGutter});
        `}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          grid-area: progressBar;
          margin-top: 2em;
        `}
      >
        {/* Headphones icon at top of page for section 1 */}
        {showForSection1 && (
          <Fragment>
            <HeadphonesSVG scale="0.15" opacity="0.75" />
            <ProgressText>Music Industry</ProgressText>
          </Fragment>
        )}
        {/* Hand/stop icon at top of page for section 2 */}
        {showForSection2 && (
          <Fragment>
            <StopHandSVG scale="0.15" opacity="0.75" />
            <ProgressText>Abuse</ProgressText>
          </Fragment>
        )}
        {/* Anonymous suit icon at top of page for section 3 */}
        {showForSection3 && (
          <Fragment>
            <SuitAndQuestionMarkSVG scale="0.15" opacity="0.75" />
            <ProgressText>Abuser(s)</ProgressText>
          </Fragment>
        )}
        {/* Clock icon at top of page for section 4 */}
        {showForSection4 && (
          <Fragment>
            <TimeMovesOnSVG scale="0.175" opacity="0.65" />
            <ProgressText>Aftermath</ProgressText>
          </Fragment>
        )}
        {/* Finished */}
        {showViewMyReportSlide && (
          <Fragment>
            <FinishSVG scale="0.08" opacity="0.75" />
            <ProgressText>You're done!</ProgressText>
          </Fragment>
        )}
      </div>
      <div
        css={css`
          grid-area: headerRight;
          width: clamp(${appGutter});
        `}
      />
      <div
        css={css`
          grid-area: emptyLeft;
          width: clamp(${appGutter});
        `}
      />
      {/* // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( MAIN APP AREA )))
// */}
      <div
        css={css`
          grid-area: questions;
          // display: flex;
          // justify-content: flex-start;
          // width: clamp(45vw, ${mainAppAreaWidth}, 95vw);
        `}
      >
        <div style={{ display: "block" }}>
          {/* TRANSITION SLIDES GO HERE */}
          {/* All four transition slide */}
          {appState.currentQuestion === -100 ? (
            <AllFourSectionTransition />
          ) : null}
          {/* First Transition Slide: The Music Industry */}
          {appState.currentQuestion === 100 ? (
            <AbuseTransitionSlide
              header="Section 1: Music Industry"
              transitionText="These questions address your connection to the music industry, if any."
              transitionTextItalics={skipSectionText}
            >
              <HeadphonesSVG />
            </AbuseTransitionSlide>
          ) : null}

          {/* Second Transition Slide: The Abuse */}
          {appState.currentQuestion === 7 ? (
            <AbuseTransitionSlide
              header="Section 2: Misconduct"
              transitionText="These next questions ask about the sexual misconduct or harm you experienced."
              transitionTextItalics={skipSectionText}
            >
              <StopHandSVG />
            </AbuseTransitionSlide>
          ) : null}

          {/* Third Transition Slide: The Abuser */}
          {appState.currentQuestion === 18 ? (
            <AbuseTransitionSlide
              header="Section 3: Abuser(s)"
              transitionText="These next questions will ask a bit of information about the person(s) who harmed you."
              transitionTextItalics={skipSectionText}
            >
              <SuitAndQuestionMarkSVG />
            </AbuseTransitionSlide>
          ) : null}

          {/* Fourth Transition Slide: Aftermath */}
          {appState.currentQuestion === 34 ? (
            <AbuseTransitionSlide
              header="Section 4: Aftermath"
              transitionText="The next section includes questions about the impact of sexual harm or misconduct."
              transitionTextItalics={skipSectionText}
            >
              <TimeMovesOnSVG opacity="0.85" />
            </AbuseTransitionSlide>
          ) : null}

          {/* Starting page of the App */}
          {appState.currentQuestion === startingSlide ? (
            <FrontPage center passToParent={unDisableButton} />
          ) : null}

          {/*
          >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 
          ((( MAIN QUESTIONS GET INSERTED HERE )))
          */}
          <div style={{ display: "block" }}>
            {addQuestions().map((arrayItem) => arrayItem)}
          </div>
          {/*
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< 
        */}
          {showViewMyReportSlide ? (
            <SubmitSurvey
              calculatePoints={functionToCalculatePoints}
              onClickSubmitAndContinue={() => skipToQuestion(43)}
            />
          ) : null}
          {appState.currentQuestion === 43 ? (
            <EndBuckets
              score={points}
              answers={answers}
              questions={props.questions}
              generatedUrl={functionToGenerateUrl()}
            />
          ) : null}
          {appState.currentQuestion === lastQuestion + 2 ? (
            <ResourcesSlide />
          ) : null}
        </div>
      </div>
      <div
        css={css`
          grid-area: emptyRight;
          width: clamp(${appGutter});
        `}
      />
      <div
        css={css`
          grid-area: emptyLeft2;
          width: clamp(${appGutter});
        `}
      />
      <div
        css={css`
          grid-area: navButtonArea;
          display: flex;
          flex-wrap: wrap;
          justify-content: ${"center"};
        `}
      >
        {/* GET STARTED BUTTON */}
        {appState.currentQuestion === startingSlide ? (
          <AppButton
            buttonId="getStarted"
            locationOnPage="center"
            buttonMargin="6em"
            buttonColor={buttonDisabled ? "grayed-out" : buttonColor}
            buttonTextColor={buttonDisabled ? "gray" : null}
            disabled={buttonDisabled}
            onStartClicked={getStartedClicked}
          >
            Get Started
          </AppButton>
        ) : null}

        {/* NEXT BUTTON */}
        {hideNextButton ? null : (
          <AppButton
            buttonId="next"
            buttonColor={buttonColor}
            locationOnPage="center"
            clickedNext={whenButtonIsClicked}
            buttonMargin={theButtonMargin}
          >
            Next
          </AppButton>
        )}

        {/* SKIP SECTION LINK */}
        {appState.currentQuestion === 7 ||
        appState.currentQuestion === 100 ||
        appState.currentQuestion === 18 ||
        appState.currentQuestion === 34 ? (
          <Fragment>
            <FlexBreak />
            <p
              css={css`
                color: ${urlColor};
                text-decoration: underline;
                font-size: 0.8em;
                &:hover {
                  cursor: pointer;
                  opacity: 0.7;
                }
              `}
              onClick={() => {
                if (appState.currentQuestion === 7) skipToQuestion(18);
                if (appState.currentQuestion === 100) skipToQuestion(7);
                if (appState.currentQuestion === 18) skipToQuestion(34);
                if (appState.currentQuestion === 34) skipToQuestion(42);
              }}
            >
              Skip section &#10095; &#10095;
            </p>
          </Fragment>
        ) : null}
        {/* Back Button - If conditions are true, hide button */}
        {hideBackButton ? null : (
          <Fragment>
            <FlexBreak />
            <p
              id="back"
              onClick={(event) => whenButtonIsClicked(event)}
              css={css`
                color: ${urlColor};
                text-decoration: underline;
                font-size: 0.8em;
                &:hover {
                  cursor: pointer;
                  opacity: 0.7;
                }
              `}
            >
              &#10094; Go back
            </p>
          </Fragment>
        )}
      </div>
      <div
        css={css`
          grid-area: emptyRight2;
          width: clamp(${appGutter});
        `}
      />
    </div>
  );
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export default SASApp;
