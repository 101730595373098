/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Box = (props) => {
  return (
    <div
      css={css`
        width: ${props.dimensions};
        height: ${props.dimensions};
        grid-area: ${props.gridArea};
        display: flex;
        flex-wrap: wrap;
        border: none;
        background-color: ${props.boxColor};
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin: ${props.margin || ""};
        & svg:first-of-type {
          margin-top: 1em;
        }
      `}
    >
      <div
        data-id={props.dataId}
        css={css`
          background-color: transparent;
          position: absolute;
          display: inline-block;
          width: ${props.dimensions};
          height: ${props.dimensions};
          border-radius: 6px;
          z-index: 2;
          &:hover {
            cursor: pointer;
          }
        `}
        onClick={props.onClick}
      ></div>
      {props.children}
    </div>
  );
};

export default Box;
