/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const BulletedList = (props) => {
  const textAlign = (() => {
    if (props.alignment === "center") {
      return "center";
    }
    if (props.alignment === "justify") {
      return "justify";
    }
    if (props.alignment === "right") {
      return "right";
    } else {
      return "left";
    }
  })();
  //   for (const eachItem: props.BulletedList){

  //   }
  const content = props.bulletedList;

  function Normalizer(contentList) {
    // let key
    // let txt
    let list = [];

    for (let key in contentList) {
      let txt = contentList[key];
      list.push(
        <li style={{ paddingBottom: "0px" }} key={key}>
          {txt}
        </li>
      );
    }

    return list;
  }

  return (
    <ul
      css={css`
        font-size: 0.9em;
        display: ${props.display};
        text-align: ${textAlign};
        margin: 1em 0 0 1.5em;
        line-height: 2;
        padding: 2px 0 2px 0;
      `}
    >
      {Normalizer(content)}
    </ul>
  );
};

export default BulletedList;
