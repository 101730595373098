/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import RadioButton from "../AnswerChoices/RadioButton";
import SingleSelectDropdown from "../AnswerChoices/SingleSelectDropdown";
import Checkbox from "../AnswerChoices/Checkbox/Checkbox";
import SingleLineTextInput from "../AnswerChoices/SingleLineTextInput";
import TextAreaInput from "../AnswerChoices/TextAreaInput";
import { lighterTextColor } from "../GlobalVariables";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// ((( GET QUESTIONS BASED ON TYPE )))
//
// Get code for the question based on the question type specified in App.js and passed into this component as props by SASApp.js
//
// Props that need to get passed in: question={each}, currentQuestion={appState.currentQuestion} handleChildChange={handleChildChange}
const GetQuestionByType = (props) => {
  const getReturnJSX = () => {
    switch (props.question.questionType) {
      case "dropdown":
        let answerChoiceArray = [];
        props.question.answerChoices.forEach((eachAnswerChoice) => {
          answerChoiceArray.push({
            value: eachAnswerChoice,
            label: eachAnswerChoice,
          });
        });
        return (
          <SingleSelectDropdown
            questionNumber={props.question.questionNumber}
            questionText={props.question.questionText}
            secondaryText={props.question.secondaryText}
            answerChoices={answerChoiceArray}
            width={props.questionAreaWidth / 2 + "px"}
            display={
              props.currentQuestion === props.question.questionNumber
                ? "block"
                : "none"
            }
            onChange={(event) =>
              event !== null
                ? props.handleChildChange(
                    event.value,
                    props.question.questionNumber,
                    "dropdown"
                  )
                : props.handleChildChange(
                    null,
                    props.question.questionNumber,
                    "dropdown"
                  )
            }
          />
        );
      case "multipleChoice":
        return (
          <RadioButton
            questionNumber={props.question.questionNumber}
            secondaryText={props.question.secondaryText}
            answerChoices={props.question.answerChoices}
            display={
              props.currentQuestion === props.question.questionNumber
                ? "flex"
                : "none"
            }
            onChange={(event) =>
              props.handleChildChange(
                event,
                props.question.questionNumber,
                "radio"
              )
            }
          />
        );
      case "checkbox":
        return (
          <Checkbox
            secondaryText={props.question.secondaryText}
            answerChoices={props.question.answerChoices}
            display={
              props.currentQuestion === props.question.questionNumber
                ? "block"
                : "none"
            }
            questionNumber={props.question.questionNumber}
            onChange={(answerChoices) => {
              props.handleChildChange(
                answerChoices,
                props.question.questionNumber,
                "checkbox"
              );
            }}
          />
        );
      case "singleLineText":
        return (
          <div
            style={{
              display:
                props.currentQuestion === props.question.questionNumber
                  ? "block"
                  : "none",
            }}
          >
            {props.question.secondaryText ? (
              <p
                css={css`
                  color: ${lighterTextColor};
                  font-size: 1.25em;
                  padding: 0.5em;
                `}
              >
                {props.question.secondaryText}
              </p>
            ) : null}
            <SingleLineTextInput
              width="50%"
              questionNumber={props.question.questionNumber}
              display={
                props.currentQuestion === props.question.questionNumber
                  ? "block"
                  : "none"
              }
              onChange={(event) =>
                event !== null
                  ? props.handleChildChange(
                      event.target.value,
                      props.question.questionNumber,
                      "singleLineText"
                    )
                  : props.handleChildChange(
                      null,
                      props.question.questionNumber,
                      "singleLineText"
                    )
              }
            />
          </div>
        );
      case "textArea":
        return (
          <div
            style={{
              display:
                props.currentQuestion === props.question.questionNumber
                  ? "block"
                  : "none",
            }}
          >
            {props.question.secondaryText ? (
              <p
                css={css`
                  color: ${lighterTextColor};
                  font-size: 1.25em;
                  padding: 0.5em;
                `}
              >
                {props.question.secondaryText}
              </p>
            ) : null}
            {(() => {
              switch (props.question.size) {
                case "verySmall":
                  return (
                    <TextAreaInput
                      width="50%"
                      questionNumber={props.question.questionNumber}
                      display={
                        props.currentQuestion === props.question.questionNumber
                          ? "block"
                          : "none"
                      }
                      onChange={(event) =>
                        event !== null
                          ? props.handleChildChange(
                              event.target.value,
                              props.question.questionNumber,
                              "textArea"
                            )
                          : props.handleChildChange(
                              null,
                              props.question.questionNumber,
                              "textArea"
                            )
                      }
                    />
                  );
                case "small":
                  return (
                    <TextAreaInput
                      width="50%"
                      questionNumber={props.question.questionNumber}
                      small
                      display={
                        props.currentQuestion === props.question.questionNumber
                          ? "block"
                          : "none"
                      }
                      onChange={(event) =>
                        event !== null
                          ? props.handleChildChange(
                              event.target.value,
                              props.question.questionNumber,
                              "textArea"
                            )
                          : props.handleChildChange(
                              null,
                              props.question.questionNumber,
                              "textArea"
                            )
                      }
                    />
                  );
                case "medium":
                  return (
                    <TextAreaInput
                      width="50%"
                      questionNumber={props.question.questionNumber}
                      medium
                      display={
                        props.currentQuestion === props.question.questionNumber
                          ? "block"
                          : "none"
                      }
                      onChange={(event) =>
                        event !== null
                          ? props.handleChildChange(
                              event.target.value,
                              props.question.questionNumber,
                              "textArea"
                            )
                          : props.handleChildChange(
                              null,
                              props.question.questionNumber,
                              "textArea"
                            )
                      }
                    />
                  );
                case "large":
                  return (
                    <TextAreaInput
                      width="50%"
                      questionNumber={props.question.questionNumber}
                      large
                      display={
                        props.currentQuestion === props.question.questionNumber
                          ? "block"
                          : "none"
                      }
                      onChange={(event) =>
                        event !== null
                          ? props.handleChildChange(
                              event.target.value,
                              props.question.questionNumber,
                              "textArea"
                            )
                          : props.handleChildChange(
                              null,
                              props.question.questionNumber,
                              "textArea"
                            )
                      }
                    />
                  );

                default:
                  return (
                    <TextAreaInput
                      width="50%"
                      questionNumber={props.question.questionNumber}
                      small
                      display={
                        props.currentQuestion === props.question.questionNumber
                          ? "block"
                          : "none"
                      }
                      onChange={(event) =>
                        event !== null
                          ? props.handleChildChange(
                              event.target.value,
                              props.question.questionNumber,
                              "textArea"
                            )
                          : props.handleChildChange(
                              null,
                              props.question.questionNumber,
                              "textArea"
                            )
                      }
                    />
                  );
              }
            })()}
          </div>
        );
      case "none":
        return null;
      default:
        //code if none of the above
        return (
          <p
            style={{
              display:
                props.currentQuestion === props.question.questionNumber
                  ? "block"
                  : "none",
            }}
          >
            Click "Next" to go to the next question.
          </p>
        );
    }
  };

  return getReturnJSX();
};

export default GetQuestionByType;
