/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useCurrentPage } from "../Hooks/useCurrentPage";

import {
  darkTealGradient,
  home,
  about,
  useViewportWidth,
} from "../GlobalVariables";

import musicTooLogo from "../Logo/softer_logo7.png";

const Navbar = (props) => {
  const { logoHeight, logoFontSize } = useViewportWidth();
  const [currentPage, setCurrentPage] = useState(useCurrentPage());
  useEffect(() => {
    props.passToParent(currentPage);
  });
  const handleClick = (event) => {
    const navText = event.target.innerText;
    setCurrentPage(navText);
  };
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        background-image: ${darkTealGradient};
        align-items: center;
        justify-content: space-between;
      `}
    >
      <div
        id="navLinks"
        css={css`
          & a {
            padding: 0;
          }
          & a {
            z-index: 2;
            color: white;
            text-decoration: none;
            margin: 1em 3em 1em 0;
            font-size: ${logoFontSize};
          }
          & a:first-of-type {
            margin-left: 2em;
          }
          & a:hover {
            color: #86bdbb;
            background-color: #e4e0e0;
            border-radius: 3px;
          }
        `}
      >
        <a
          css={css`
            ${currentPage === home ? "border-bottom: 2px solid white;" : ""}
          `}
          onClick={handleClick}
          href="/"
        >
          {home}
        </a>
        <a
          css={css`
            ${currentPage === about ? "border-bottom: 2px solid white;" : ""}
          `}
          onClick={handleClick}
          href="/about/"
        >
          {about}
        </a>
      </div>
      <img
        id="logo-image"
        src={musicTooLogo}
        css={css`
          justify-self: flex-end;
          padding: 1em;
          height: ${logoHeight};
        `}
        alt="musictoo logo"
      />
    </div>
  );
};

export default Navbar;
