/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import QuestionText from "../../../Text/QuestionText";
import HeadphonesSVG from "../Section 1 - Music Industry/HeadphonesSVG";
import StopHandSVG from "../Section 2 - The Abuse/StopHandSVG";
import SuitAndQuestionMarkSVG from "../Section 3 - The Abuser/SuitAndQuestionMarkSVG";
import TimeMovesOnSVG from "../Section 4 - Aftermath/TimeMovesOnSVG";
import { transitionTextSize } from "../../../GlobalVariables";
import TransitionGraphicWrapper from "./TransitionGraphicWrapper";

const AllFourSectionTransition = (props) => {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <QuestionText center>What To Expect</QuestionText>
      <p
        css={css`
          font-size: ${transitionTextSize};
        `}
      >
        In order to assess your potential legal claims, we invite you to answer
        a few questions about your experience. They fall under four categories:
      </p>
      <div
        css={css`
          margin: 3em 0 3em 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2em;
        `}
      >
        <TransitionGraphicWrapper text="Music Industry">
          <HeadphonesSVG scale="0.5" />
        </TransitionGraphicWrapper>
        <TransitionGraphicWrapper text="Abuse">
          <StopHandSVG scale="0.5" />
        </TransitionGraphicWrapper>
        <TransitionGraphicWrapper text="Abuser(s)">
          <SuitAndQuestionMarkSVG scale="0.5" />
        </TransitionGraphicWrapper>
        <TransitionGraphicWrapper text="Aftermath">
          <TimeMovesOnSVG scale="0.53" opacity="0.85" />
        </TransitionGraphicWrapper>
      </div>
      <p>
        Based on your answers, you will receive a personalized case analysis to
        help you determine best next steps.
      </p>
    </div>
  );
};

export default AllFourSectionTransition;
