/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { primaryColor, debug } from "../../GlobalVariables";

const CheckboxSVG = (props) => {
  const pathLength = 126.36953735351562; // if you change the size of the checkmark, you may need to get the path length again, by setting "debug" to true and checking the console.
  const elementRef = useRef();
  useEffect(() => {
    if (debug) {
      const pathElement = elementRef.current;
      console.log("total length", pathElement.getTotalLength());
    }
    if (props.uncheckAnimation === "false") {
      elementRef.current.style.strokeDashoffset = "0";
    }
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      css={css`
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        margin-top: -40px;
        left: 23px;
        pointer-events: none;
      `}
    >
      <path
        css={css`
          stroke-dasharray: ${pathLength};
          stroke-dashoffset: ${props.pathLength};
          transition: stroke-dashoffset 0.2s ease-in-out;
          stroke: ${primaryColor};
          stroke-width: 13px;
          stroke-linecap: round;
          stroke-linejoin: round;
          fill: none;
        `}
        ref={elementRef}
        d="M16.667,62.167c3.109,5.55,7.217,10.591,10.926,15.75 c2.614,3.636,5.149,7.519,8.161,10.853c-0.046-0.051,1.959,2.414,2.692,2.343c0.895-0.088,6.958-8.511,6.014-7.3 c5.997-7.695,11.68-15.463,16.931-23.696c6.393-10.025,12.235-20.373,18.104-30.707C82.004,24.988,84.802,20.601,87,16"
      />
    </svg>
  );
};

export default CheckboxSVG;
