import { useState } from "react";
import { debug } from "../../GlobalVariables";
import CheckboxSVG from "./CheckboxSVG";

const pathLength = 126.36953735351562; // if you change the size of the checkmark, you may need to get the path length again, by going into the CheckboxSVG component and inside useEffect, take the current value of a ref to the path and run .getTotalLength() on it.

const CheckboxItem = (props) => {
  const [showCheckmark, setShowCheckmark] = useState(() => {
    const storedAnswers = window.sessionStorage.getItem("answers");
    if (!storedAnswers) return false;
    const answerToCurrentQuestion =
      JSON.parse(storedAnswers)[props.questionNumber];
    if (answerToCurrentQuestion) {
      if (answerToCurrentQuestion.includes(props.answer)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const changeHandler = (event) => {
    const relevantAnswer = event.target.innerText;
    let oldState;
    setShowCheckmark((prevState) => {
      oldState = prevState;
      return !prevState;
    });
    //is checked
    if (!oldState) {
      props.parentFunction([relevantAnswer, "checked"]);
    }
    // is unchecked
    else {
      props.parentFunction([relevantAnswer, "unchecked"]);
    }
    if (debug) console.log(event);
  };
  return (
    <li onClick={changeHandler}>
      <label>
        {/* Either remove the input and stop relying on it, and instead rely on the showCheckmark state, or use a Ref to change the "checked" status of the input */}
        {/* <input id={props.answer} type="checkbox" onChange={changeHandler} /> */}
        {props.answer}
      </label>
      {props.uncheckAnimation ? (
        <CheckboxSVG
          pathLength={showCheckmark ? 0 : pathLength}
          uncheckAnimation="true"
        />
      ) : showCheckmark ? (
        <CheckboxSVG pathLength={pathLength} uncheckAnimation="false" />
      ) : null}
    </li>
  );
};

export default CheckboxItem;
