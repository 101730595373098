/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import QuestionText from "../../Text/QuestionText";
import Box from "./Chutes/Box";
import ChecklistSVG from "./Chutes/ChecklistSVG";
import FlexBreak from "../../UI/FlexBreak";
import BoxTitle from "./Chutes/BoxTitle";
import BoxText from "./Chutes/BoxText";
// import AppButton from "../../Buttons/AppButton";
import SpeakToLawyerSVG from "./Chutes/SpeakToLawyersSVG";
import InformationSVG from "./Chutes/InformationSVG";
import SubHeading from "../../Text/SubHeading";
import Dot from "./Chutes/Dot";
import { useState } from "react";
import {
  herLightBlue,
  herLightBrown,
  herLightRed,
  useViewportWidth,
} from "../../GlobalVariables";

const boxDimensions = "225px";
const svgDimensions = "50";
const boxItemsColor = "black";

const FrontPage = (props) => {
  const { boxJustification } = useViewportWidth();
  const [checked, setChecked] = useState({
    dot1: false,
    dot2: false,
    dot3: false,
  });
  const toggleChecked = (event) => {
    const theId = event.target.dataset.id;
    setChecked((prevState) => {
      const oldState = prevState[theId];
      let newState = { dot1: false, dot2: false, dot3: false };
      newState[theId] = !oldState;
      props.passToParent(newState);
      return newState;
    });
  };
  return (
    <div
      css={css`
        display: grid;
        grid-template-areas:
          "title"
          "subtitle"
          "box";
      `}
    >
      {/* App Title */}
      <QuestionText gridArea="title" center={props.center} right={props.right}>
        Experienced sexual harm? We're here to help.
      </QuestionText>

      {/* SubHeading */}
      <SubHeading gridArea="subtitle">
        Click the option that best describes you:
      </SubHeading>

      {/* Flexbox inside box gride to hold 3 boxes */}
      <div
        css={css`
          grid-area: box;
          display: flex;
          justify-content: ${boxJustification};
          flex-flow: row wrap;
          gap: 2em;
        `}
      >
        {/* Box 1 */}
        <Box
          dataId="dot1"
          dimensions={boxDimensions}
          boxColor={herLightBrown}
          onClick={toggleChecked}
        >
          <ChecklistSVG fillColor={boxItemsColor} dimensions={svgDimensions} />
          <FlexBreak />
          <BoxTitle color={boxItemsColor}>Anonymous Case Assessment</BoxTitle>
          <FlexBreak />
          <BoxText color={boxItemsColor}>
            I'm not sure if I have a legal claim.
          </BoxText>
          <FlexBreak />
          <Dot id="dot1" onClick={toggleChecked} checked={checked["dot1"]} />
        </Box>

        {/* Box 2 */}
        <Box
          dataId="dot2"
          dimensions={boxDimensions}
          boxColor={herLightRed}
          onClick={toggleChecked}
        >
          <SpeakToLawyerSVG
            fillColor={boxItemsColor}
            dimensions={svgDimensions}
          />
          <FlexBreak />
          <BoxTitle color={boxItemsColor}>Speak to a Legal Advocate</BoxTitle>
          <FlexBreak />
          <BoxText color={boxItemsColor}>I'm ready to seek justice.</BoxText>
          <FlexBreak />
          <Dot id="dot2" onClick={toggleChecked} checked={checked["dot2"]} />
        </Box>
        {/* Box 3 */}
        <Box
          dataId="dot3"
          dimensions={boxDimensions}
          boxColor={herLightBlue}
          onClick={toggleChecked}
        >
          <InformationSVG
            fillColor={boxItemsColor}
            dimensions={svgDimensions}
          />
          <FlexBreak />
          <BoxTitle color={boxItemsColor}>Resources</BoxTitle>
          <FlexBreak />
          <BoxText color={boxItemsColor}>
            I'd like resources to help me cope and heal.
          </BoxText>
          <FlexBreak />
          <Dot id="dot3" onClick={toggleChecked} checked={checked["dot3"]} />
        </Box>
      </div>
    </div>
  );
};

export default FrontPage;
