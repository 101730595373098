/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Fade from "react-reveal/Fade";
import { siteFont, h2FontSize, mainBlue } from "../globalVariables";

const maxHeight = "7.5em";
const maxWidth = "8.5em";
const itemMargin = "1em .5em 1em .5em";
const animationDelayIncrement = 250;

const AwardsBar = (props) => {
  return (
    <div
      css={css`
        font-family: ${siteFont};
        & h2 {
          text-align: center;
          font-size: ${h2FontSize}em;
          color: ${mainBlue};
          margin-bottom: 0.75em;
        }
      `}
    >
      {!props.noTitle && <h2>Award Winning Law Firm</h2>}
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-items: center;
          padding-top: 0.5em;
          padding-left: 0.5em;
          padding-right: 0.5em;
          padding-bottom: 2em;
        `}
      >
        <Fade top>
          <img
            css={css`
              max-height: ${maxHeight};
              margin: ${itemMargin};
            `}
            // className="animate__animated animate__fadeInDown"
            src="https://www.classlawgroup.com/wp-content/uploads/2019-Clay-Awards-2.jpg"
            alt="CLAY award"
          />
        </Fade>
        <Fade top delay={animationDelayIncrement}>
          <img
            css={css`
              max-height: ${maxHeight};
              max-width: ${maxWidth};
              margin: ${itemMargin};
            `}
            src="https://www.classlawgroup.com/wp-content/uploads/titans-logo-2019.png"
            alt="titan of plaintiffs bar award"
          />
        </Fade>
        <Fade top delay={2 * animationDelayIncrement}>
          <img
            css={css`
              max-height: ${maxHeight};
              margin: ${itemMargin};
            `}
            src="https://www.classlawgroup.com/wp-content/uploads/pgoty-2019_series1.png"
            alt="practice group of the year award"
          />
        </Fade>
        <Fade top delay={3 * animationDelayIncrement}>
          <img
            css={css`
              max-height: ${maxHeight};
              margin: ${itemMargin};
            `}
            src="https://www.classlawgroup.com/wp-content/uploads/bestLawFirms.jpg"
            alt="best law firm ranking"
          />
        </Fade>
        <Fade top delay={4 * animationDelayIncrement}>
          <img
            css={css`
              max-height: ${maxHeight};
              max-width: ${maxWidth};
              margin: ${itemMargin};
            `}
            src="https://www.classlawgroup.com/wp-content/uploads/lawdragon2.jpg"
            alt="top plaintiffs lawyers award"
          />
        </Fade>
        <Fade top delay={5 * animationDelayIncrement}>
          <img
            css={css`
              max-height: ${maxHeight};
              margin: ${itemMargin};
            `}
            src="https://www.classlawgroup.com/wp-content/uploads/Top-Plaintiff-Lawyers-2019-254x300.jpg"
            alt="top plaintiffs lawyers award"
          />
        </Fade>
      </div>
    </div>
  );
};

export default AwardsBar;
