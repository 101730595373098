/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const TransitionGraphicWrapper = (props) => {
  return (
    <div>
      {props.children}
      <p
        css={css`
          font-weight: bold;
          color: #7d7572;
        `}
      >
        {props.text}
      </p>
    </div>
  );
};

export default TransitionGraphicWrapper;
