import useWindowDimensions from "./Hooks/useWindowDimensions";

const debug = false;

const herLightBrown = "#d5cbc8";
const herLightRed = "#f2a59b";
const herLightBlue = "#c0cde3";
const darkTealGradient = "linear-gradient(to right, #86BDBB 0, #3E5B5A 100%)";
const lighterTealGradient =
  "linear-gradient(256.31deg, #87BDC3 40.21%, rgba(134, 204, 211, 0.78) 123.76%)";
// "linear-gradient(to right, #87BDC3 0, #98cfd5 100%)";
// const primaryColor = "#6bbaf0";
// const primaryColor = "#86BDBB";
const primaryColor = "#92c8ce";
const lighterPrimaryColor = "#d1eafa";
const darkerPrimaryColor = "#2e9fea";
const complementaryLighterPrimaryColor = "#8ab8d6";
const complementaryDarkerPrimaryColor = "#5493bd";
const textColor = "#42494f";
const lighterTextColor = "#7f7f7f";
const normalBorderColorForInput = "hsl(0, 0%, 70%)";
const fontFamily =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
const fontSize = "16px";
const transitionTextSize = "20px";
const answerChoicePadding = "24px";
const buttonColor = "lighter-teal-gradient";
const urlColor = "rgb(98, 101, 136)";

const useViewportWidth = () => {
  const { width } = useWindowDimensions();
  let websiteGutter;
  const initial = 15;
  if (width > 1200) websiteGutter = initial;
  if (width <= 1200 && width > 1025) websiteGutter = initial * (3 / 4);
  if (width <= 1025 && width > 769) websiteGutter = initial * (1 / 2);
  if (width <= 769 && width > 481) websiteGutter = initial * (1 / 4);
  if (width <= 481) websiteGutter = initial * (1 / 8);

  let appGutter;
  const initialTwo = 2;
  if (width > 1200) appGutter = initialTwo;
  if (width <= 1200 && width > 1025) appGutter = initialTwo * (3 / 4);
  if (width <= 1025 && width > 769) appGutter = initialTwo * (1 / 2);
  if (width <= 769 && width > 481) appGutter = initialTwo * (1 / 4);
  if (width <= 481) appGutter = initialTwo * (1 / 8);

  const mainApp = 98 - 2 * websiteGutter - 2 * appGutter;

  let boxJustification;
  if (width > 967) boxJustification = "space-between";
  if (width <= 967) boxJustification = "center";

  let logoHeight;
  if (width < 450) logoHeight = "1.5em";
  else logoHeight = "2em";
  if (width <= 405) logoHeight = "1.25em";
  if (width < 380) logoHeight = "1em";

  let logoFontSize;
  if (width < 356) logoFontSize = "90%";
  else logoFontSize = "100%";
  if (width < 335) logoFontSize = "80%";
  if (width < 314) logoFontSize = "70%";
  if (width < 293) logoFontSize = "60%";

  let videoScale;
  if (width < 1338) videoScale = 1;
  else videoScale = 1.3;

  let flexWrap;
  if (width < 1072) flexWrap = "wrap";
  else flexWrap = "nowrap";

  return {
    websiteGutter: websiteGutter.toString() + "vw",
    appGutterWidth: appGutter.toString() + "vw",
    mainAppAreaWidth: mainApp.toString() + "vw",
    boxJustification,
    logoHeight,
    logoFontSize,
    videoScale,
    flexWrap,
  };
};

const ListOfStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const pointsPerAnswer = {
  1: {
    "Yes": 0,
    "No": 0,
  },
  2: {
    "Yes": 0,
    "No": 0,
  },
  3: {
    "Artist": 10,
    "Worked at a record label": 15,
    "Worked at an artist management company": 15,
    "Worked at a promotion company": 15,
    "Worked at some other music company": 15,
    "Other": 5,
    "maximum": 15,
  },
  4: {
    "Other": 0,
  },
  5: {
    "Yes": 0,
    "No": 0,
  },
  6: {
    "Yes": 0,
    "No": 0,
  },
  7: {
    "Continue": 0,
    "Skip forward to the next section: questions about the perpetrator": 0,
  },
  8: {
    "Physical contact (any unwelcome touching of your body, such as groping)": 15,
    "Verbal harassment or threats": 10,
    "Sexual contact": 15,
    "Online or written harassment": 10,
    "Other": 5,
    "maximum": 20,
  },
  9: {
    "Other": 0,
  },
  10: {
    "Once": 5,
    "More than once": 15,
  },
  11: {
    "Within the last year": 15,
    "Between 1 and 5 years ago": 10,
    "More than 5 years ago": 5,
  },
  12: {
    "California": 20,
    "New York": 20,
  },
  13: {
    "Within the last year": 15,
    "Between 1 and 5 years ago": 10,
    "More than 5 years ago": 5,
  },
  14: {
    "A week or less": 5,
    "A month or less": 10,
    "A few months or longer": 15,
  },
  15: {
    "Yes": 0,
    "No": 0,
  },
  16: {
    "California": 20,
    "New York": 20,
  },
  17: {
    "Yes": 5,
    "No": 15,
    "No, but I was under 18 when I first met the perpetrator(s)": 10,
  },
  18: {
    "Continue": 0,
    "Skip forward to the next section: questions about the aftermath of the abuse": 0,
  },
  19: {
    "One person": 5,
    "Multiple people": 10,
  },
  20: {
    "Yes": 5,
    "No": 0,
  },
  21: {
    "Yes": 10,
    "Not exactly, but they did have the ability to harm my career or reputation": 5,
    "No": 0,
  },
  22: {
    "Yes": 0,
    "No": 0,
  },
  23: {
    "Yes": 5,
    "No": 0,
  },
  24: {
    "Artist": 10,
    "Worked at a record label": 10,
    "Worked at an artist management company": 10,
    "Worked at a promotion company": 10,
    "Worked at some other music company": 10,
    "Other": 5,
    "I don't know": 5,
    "maximum": 10,
  },
  25: {
    "Other": 0,
  },
  26: {
    "Name of label": 0,
  },
  27: {
    "Yes": 5,
    "No": 0,
  },
  28: {
    "Yes": 10,
    "Not exactly, but they do have the ability to harm my career or reputation": 5,
    "No": 0,
  },
  29: {
    "Yes": 0,
    "No": 0,
  },
  30: {
    "Yes": 5,
    "No": 0,
  },
  31: {
    "Artist": 10,
    "Worked at a record label": 10,
    "Worked at an artist management company": 10,
    "Worked at a promotion company": 10,
    "Worked at some other music company": 10,
    "Other": 5,
    "I don't know": 5,
    "maximum": 10,
  },
  32: {
    "Textbox": 0,
  },
  33: {
    "Text box": 0,
  },
  34: {
    "Continue": 0,
    "Skip these questions and jump to end": 0,
  },
  35: {
    "Forced to sign an NDA (non-disclosure agreement)": 5,
    "Reprimanded at my job": 5,
    "Fired or forced to quit my job": 10,
    "Lost out on work opportunities": 5,
    "Blacklisted from the industry": 5,
    "Experienced damage to my reputation": 5,
    "Other": 0,
    "I don't know": 0,
    "maximum": 10,
  },
  36: {
    "Other": 0,
  },
  37: {
    "Yes": 0,
    "No": 0,
  },
  38: {
    "HR or supervisor": 15,
    "Police": 10,
    "Family member or friend": 5,
    "Other": 5,
    "maximum": 15,
  },
  39: {
    "Other": 0,
  },
  40: {
    "Perpetrator(s) is/are a high-level employee": 20,
    "Perpetrator(s) is/are a mid-level employee": 10,
    "Perpetrator(s) is/are a regular employee": 5,
    "maximum": 20,
  },
  41: {
    "Perpetrator(s) is/are famous": 15,
    "Perpetrator(s) is/are somewhat famous": 10,
    "Perpetrator(s) is/are relatively niche": 5,
    "Perpetrator(s) is/are very niche": 5,
    "maximum": 15,
  },
};

let pathDefs = {
  cross: ["M 10 10 L 90 90", "M 90 10 L 10 90"],
  fill: [
    "M15.833,24.334c2.179-0.443,4.766-3.995,6.545-5.359 c1.76-1.35,4.144-3.732,6.256-4.339c-3.983,3.844-6.504,9.556-10.047,13.827c-2.325,2.802-5.387,6.153-6.068,9.866 c2.081-0.474,4.484-2.502,6.425-3.488c5.708-2.897,11.316-6.804,16.608-10.418c4.812-3.287,11.13-7.53,13.935-12.905 c-0.759,3.059-3.364,6.421-4.943,9.203c-2.728,4.806-6.064,8.417-9.781,12.446c-6.895,7.477-15.107,14.109-20.779,22.608 c3.515-0.784,7.103-2.996,10.263-4.628c6.455-3.335,12.235-8.381,17.684-13.15c5.495-4.81,10.848-9.68,15.866-14.988 c1.905-2.016,4.178-4.42,5.556-6.838c0.051,1.256-0.604,2.542-1.03,3.672c-1.424,3.767-3.011,7.432-4.723,11.076 c-2.772,5.904-6.312,11.342-9.921,16.763c-3.167,4.757-7.082,8.94-10.854,13.205c-2.456,2.777-4.876,5.977-7.627,8.448 c9.341-7.52,18.965-14.629,27.924-22.656c4.995-4.474,9.557-9.075,13.586-14.446c1.443-1.924,2.427-4.939,3.74-6.56 c-0.446,3.322-2.183,6.878-3.312,10.032c-2.261,6.309-5.352,12.53-8.418,18.482c-3.46,6.719-8.134,12.698-11.954,19.203 c-0.725,1.234-1.833,2.451-2.265,3.77c2.347-0.48,4.812-3.199,7.028-4.286c4.144-2.033,7.787-4.938,11.184-8.072 c3.142-2.9,5.344-6.758,7.925-10.141c1.483-1.944,3.306-4.056,4.341-6.283c0.041,1.102-0.507,2.345-0.876,3.388 c-1.456,4.114-3.369,8.184-5.059,12.212c-1.503,3.583-3.421,7.001-5.277,10.411c-0.967,1.775-2.471,3.528-3.287,5.298 c2.49-1.163,5.229-3.906,7.212-5.828c2.094-2.028,5.027-4.716,6.33-7.335c-0.256,1.47-2.07,3.577-3.02,4.809",
  ],
  checkmark: [
    "M16.667,62.167c3.109,5.55,7.217,10.591,10.926,15.75 c2.614,3.636,5.149,7.519,8.161,10.853c-0.046-0.051,1.959,2.414,2.692,2.343c0.895-0.088,6.958-8.511,6.014-7.3 c5.997-7.695,11.68-15.463,16.931-23.696c6.393-10.025,12.235-20.373,18.104-30.707C82.004,24.988,84.802,20.601,87,16",
  ],
  circle: [
    "M34.745,7.183C25.078,12.703,13.516,26.359,8.797,37.13 c-13.652,31.134,9.219,54.785,34.77,55.99c15.826,0.742,31.804-2.607,42.207-17.52c6.641-9.52,12.918-27.789,7.396-39.713 C85.873,20.155,69.828-5.347,41.802,13.379",
  ],
  boxfill: [
    "M6.987,4.774c15.308,2.213,30.731,1.398,46.101,1.398 c9.74,0,19.484,0.084,29.225,0.001c2.152-0.018,4.358-0.626,6.229,1.201c-5.443,1.284-10.857,2.58-16.398,2.524 c-9.586-0.096-18.983,2.331-28.597,2.326c-7.43-0.003-14.988-0.423-22.364,1.041c-4.099,0.811-7.216,3.958-10.759,6.81 c8.981-0.104,17.952,1.972,26.97,1.94c8.365-0.029,16.557-1.168,24.872-1.847c2.436-0.2,24.209-4.854,24.632,2.223 c-14.265,5.396-29.483,0.959-43.871,0.525c-12.163-0.368-24.866,2.739-36.677,6.863c14.93,4.236,30.265,2.061,45.365,2.425 c7.82,0.187,15.486,1.928,23.337,1.903c2.602-0.008,6.644-0.984,9,0.468c-2.584,1.794-8.164,0.984-10.809,1.165 c-13.329,0.899-26.632,2.315-39.939,3.953c-6.761,0.834-13.413,0.95-20.204,0.938c-1.429-0.001-2.938-0.155-4.142,0.436 c5.065,4.68,15.128,2.853,20.742,2.904c11.342,0.104,22.689-0.081,34.035-0.081c9.067,0,20.104-2.412,29.014,0.643 c-4.061,4.239-12.383,3.389-17.056,4.292c-11.054,2.132-21.575,5.041-32.725,5.289c-5.591,0.124-11.278,1.001-16.824,2.088 c-4.515,0.885-9.461,0.823-13.881,2.301c2.302,3.186,7.315,2.59,10.13,2.694c15.753,0.588,31.413-0.231,47.097-2.172 c7.904-0.979,15.06,1.748,22.549,4.877c-12.278,4.992-25.996,4.737-38.58,5.989c-8.467,0.839-16.773,1.041-25.267,0.984 c-4.727-0.031-10.214-0.851-14.782,1.551c12.157,4.923,26.295,2.283,38.739,2.182c7.176-0.06,14.323,1.151,21.326,3.07 c-2.391,2.98-7.512,3.388-10.368,4.143c-8.208,2.165-16.487,3.686-24.71,5.709c-6.854,1.685-13.604,3.616-20.507,4.714 c-1.707,0.273-3.337,0.483-4.923,1.366c2.023,0.749,3.73,0.558,5.95,0.597c9.749,0.165,19.555,0.31,29.304-0.027 c15.334-0.528,30.422-4.721,45.782-4.653",
  ],
  swirl: [
    "M49.346,46.341c-3.79-2.005,3.698-10.294,7.984-8.89 c8.713,2.852,4.352,20.922-4.901,20.269c-4.684-0.33-12.616-7.405-14.38-11.818c-2.375-5.938,7.208-11.688,11.624-13.837 c9.078-4.42,18.403-3.503,22.784,6.651c4.049,9.378,6.206,28.09-1.462,36.276c-7.091,7.567-24.673,2.277-32.357-1.079 c-11.474-5.01-24.54-19.124-21.738-32.758c3.958-19.263,28.856-28.248,46.044-23.244c20.693,6.025,22.012,36.268,16.246,52.826 c-5.267,15.118-17.03,26.26-33.603,21.938c-11.054-2.883-20.984-10.949-28.809-18.908C9.236,66.096,2.704,57.597,6.01,46.371 c3.059-10.385,12.719-20.155,20.892-26.604C40.809,8.788,58.615,1.851,75.058,12.031c9.289,5.749,16.787,16.361,18.284,27.262 c0.643,4.698,0.646,10.775-3.811,13.746",
  ],
  diagonal: [
    "M16.053,91.059c0.435,0,0.739-0.256,0.914-0.768 c3.101-2.85,5.914-6.734,8.655-9.865C41.371,62.438,56.817,44.11,70.826,24.721c3.729-5.16,6.914-10.603,10.475-15.835 c0.389-0.572,0.785-1.131,1.377-1.521",
  ],
  list: [
    "M1.986,8.91c41.704,4.081,83.952,5.822,125.737,2.867 c17.086-1.208,34.157-0.601,51.257-0.778c21.354-0.223,42.706-1.024,64.056-1.33c18.188-0.261,36.436,0.571,54.609,0.571",
    "M3.954,25.923c9.888,0.045,19.725-0.905,29.602-1.432 c16.87-0.897,33.825-0.171,50.658-2.273c14.924-1.866,29.906-1.407,44.874-1.936c19.9-0.705,39.692-0.887,59.586,0.45 c35.896,2.407,71.665-1.062,107.539-1.188",
  ],
};

let animDefs = {
  cross: { speed: 0.2, easing: "ease-in-out" },
  fill: { speed: 0.8, easing: "ease-in-out" },
  checkmark: { speed: 0.2, easing: "ease-in-out" },
  circle: { speed: 0.2, easing: "ease-in-out" },
  boxfill: { speed: 0.8, easing: "ease-in" },
  swirl: { speed: 0.8, easing: "ease-in" },
  diagonal: { speed: 0.2, easing: "ease-in-out" },
  list: { speed: 0.3, easing: "ease-in-out" },
};

const home = "Home";
const about = "About";

const slideTransitionKeyframe = `
  @keyframes glg-animate-slide-in {
    0% {
      transform: translate(-10em, -2.5em);
    }
    100% {
      transform: none;
    }
  }
  `;

const animationCSS = `glg-animate-slide-in 0.25s ease-in`;

export {
  herLightBlue,
  herLightBrown,
  herLightRed,
  darkTealGradient,
  lighterTealGradient,
  primaryColor,
  lighterPrimaryColor,
  darkerPrimaryColor,
  complementaryLighterPrimaryColor,
  complementaryDarkerPrimaryColor,
  textColor,
  buttonColor,
  lighterTextColor,
  fontFamily,
  fontSize,
  transitionTextSize,
  normalBorderColorForInput,
  answerChoicePadding,
  ListOfStates,
  pointsPerAnswer,
  useViewportWidth,
  urlColor,
  pathDefs,
  animDefs,
  home,
  about,
  debug,
  slideTransitionKeyframe,
  animationCSS,
};
